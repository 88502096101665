<template>
  <div
    class="row pl-9 mb-1"
    style="position: sticky;top: 90px;z-index: 100;"
  >
    <p class="font-size-h4 font-weight-bold mb-1">Tổng quan</p>
    <div class="col-12">
      <div class="row mt-2 mb-4">
        <p class="dashed w-100 m-0"></p>
      </div>
      <div
        class="row mb-4"
        v-if="product"
      >
        <h4
          class="pl-0 mb-0 font-weight-bolder text-center"
          for="input-status"
        >{{product.productName}}</h4>
      </div>
      <div class="row">
        <label
          style="font-weight: 600"
          for="input-status"
          class="pl-0 mr-2 "
        >Chương trình:</label>
        <p class="badge badge-secondary">{{valuationRequest.searchTradeInProgram}}</p>
      </div>
      <div class="row mt-2 mb-4">
        <p class="dashed w-100 m-0"></p>
      </div>
      <div class="row mb-4">
        <label
          style="font-weight: 600"
          for="input-status"
          class="col-md-7 pl-0"
        >Giá thu dự kiến:</label>
        <h6><mark>{{ convertPrice(finalBuyingPrice) }}</mark></h6>
      </div>
    </div>
  </div>

</template>

<script>
import { convertPrice } from '@/utils/common';

export default {
  name: 'OverviewSidebar',
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    valuationRequest: {
      type: Object,
      required: true,
    },
    finalBuyingPrice: {
      type: Number,
      required: true,
    },
    totalCriteriaPrice: {
      type: Number,
      required: true,
    },
  },
  computed: {
    statusClass() {
      if (this.totalCriteriaPrice > 0) return 'text-success';
      if (this.totalCriteriaPrice < 0) return 'text-danger';
      return 'text-dark';
    },
  },
  methods: {
    convertPrice,
  },
};
</script>

<style scoped>
.dashed {
  border-bottom: 1px dashed #ccc;
  margin: 1rem 0;
}
</style>
