<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="'Kiểm tra sản phẩm tính hoa hồng ngành cửa hàng'"
        >
          <template v-slot:preview>
            <!-- Header Filter -->
            <div class="col-md-12 mb-5">
              <b-form>
                <b-row class="mb-3">
                  <b-col md="12">
                    <label class="mb-1">Sản phẩm:</label>
                    <Autosuggest
                      :model="selectedProductName"
                      :suggestions="listProductFilter"
                      placeholder="sản phẩm"
                      :limit="10"
                      @select="onSelectProducts($event)"
                      @change="onProductChange"
                      suggestionName="suggestionName"
                    />
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col md="8">
                    <label class="mb-0">Cửa hàng:</label>
                    <Autosuggest
                      :model="selectedStoreName"
                      :suggestions="filteredStoreOptions"
                      placeholder="cửa hàng"
                      :limit="10"
                      @select="onSelectStores($event)"
                      @change="onInputStoreChange"
                      suggestionName="suggestionName"
                    />
                  </b-col>
                  <b-col md="2">
                    <label class="mb-0">Tháng năm</label>
                    <date-picker
                      v-model="selectedMonth"
                      placeholder="Chọn tháng năm"
                      class="form-control form-control-sm"
                      :config="dpConfigs.monthReport"
                    ></date-picker>
                  </b-col>
                  <b-col md="2" class="d-flex flex-column align-item-end">
                    <label class="mb-0 invisible">Tìm kiếm</label>
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="handleCheckReward"
                    >
                      <i style="font-size: 1rem" class="fas fa-search"></i>Kiểm
                      tra
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <!-- Table Data -->
            <div class="px-4">
              <b-table
                id="my-table-main"
                class="table-bordered table-hover"
                :fields="fields"
                :items="rewardPrograms"
                v-if="renderListRewardProgram"
                :busy="onLoading"
              >
                <template v-slot:cell(name)="row">
                  <v-icon
                    v-if="hasViewRewardAndCategoryDetail"
                    small
                    class="text-primary mx-2"
                    v-b-tooltip
                    @click="handleViewDetailProgram(row.item.id)"
                    title="Xem chi tiết chương trình HH"
                    >mdi-eye</v-icon
                  >
                  {{ row.item.name }}
                </template>
                <template v-slot:cell(categories)="row">
                  <div
                    v-for="(category, index) in row.item.categories"
                    :key="index"
                  >
                    <div class="my-1">
                      <v-icon
                        v-if="hasViewRewardAndCategoryDetail"
                        small
                        class="text-primary mx-2"
                        v-b-tooltip
                        @click="
                          handleViewDetailCategory(
                            category.rewardProductCategoryId,
                          )
                        "
                        title="Xem chi tiết nhóm HH"
                        >mdi-eye</v-icon
                      >
                      {{ category.rewardProductCategoryName }}
                    </div>
                  </div>
                </template>
                <template v-slot:cell(amount1)="row">
                  {{ row.item.amount1 }}
                </template>
                <template v-slot:cell(amount1Operator)="row">
                  {{ getAmountOperatorText(row.item.amount1Operator) }}
                </template>
                <template v-slot:cell(amount2)="row">
                  {{ row.item.amount2 }}
                </template>
                <template v-slot:cell(amount2Operator)="row">
                  {{ getAmountOperatorText(row.item.amount2Operator) }}
                </template>
              </b-table>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import debounce from 'lodash/debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import { removeAccents } from '@/utils/common';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { formatDate } from '../../../utils/date';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { getToastInstance } from '@/utils/toastHelper';
import localData from '../../../utils/saveDataToLocal';

export default {
  data() {
    const currentMonth = new Date();

    return {
      selectedProductName: null,
      selectedProductId: null,
      selectedStoreName: null,
      selectedStoreId: null,
      rewardPrograms: [],
      listProductFilter: [],
      filteredStoreOptions: [],
      renderListRewardProgram: false,
      onLoading: false,
      selectedMonth: currentMonth,
      dpConfigs: {
        monthReport: {
          format: 'MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      fields: [
        {
          key: 'name',
          label: 'Tên chương trình',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'categories',
          label: 'Tên nhóm HH',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amount1',
          label: 'Giá trị 1',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'amount1Operator',
          label: 'Toán tử 1',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'dt',
          label: 'DT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-center',
          formatter: () => {
            return 'DT';
          },
        },
        {
          key: 'amount2Operator',
          label: 'Toán tử 2',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'amount2',
          label: 'Giá trị 2',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Kiểm tra sản phẩm tính hoa hồng ngành cửa hàng',
        route: 'reward-check',
      },
      { title: 'Kiểm tra sản phẩm tính hoa hồng ngành cửa hàng' },
    ]);
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    hasViewRewardAndCategoryDetail() {
      return localData.checkPermission('REWARD_VIEW');
    }
  },
  created() {
    this.fetchStore();
  },
  methods: {
    getAmountOperatorText(value) {
      const operatorMap = {
        1: '--',
        2: '<',
        3: '>',
        4: '≤',
        5: '≥',
      };
      return operatorMap[value] || '';
    },
    ...getToastInstance(),
    formatDate: formatDate,
    onProductChange(name) {
      this.debounceInputProduct(name);
    },
    debounceInputProduct: debounce(function (name) {
      this.searchProduct(name);
    }, TIME_TRIGGER),
    onSelectProducts(option) {
      this.selectedProductName = option.item.productName;
      this.selectedProductId = option.item.id;
    },
    searchProduct(name) {
      const params = { name };
      this.onLoading = true;

      ApiService.query('product/searchProductByName', { params })
        .then((response) => {
          this.listProductFilter = [];

          response.data.data.forEach((item) => {
            let suggestionName = item.productName;
            if (item.productCode !== '') {
              suggestionName = item.productCode + ' - ' + item.productName;
            }
            const product = {
              id: item.id,
              name: item.productName,
              code: item.productCode,
              suggestionName: suggestionName,
            };
            this.listProductFilter.push(product);
          });
        })
        .finally(() => {
          this.onLoading = false;
        });
    },
    onInputStoreChange(text) {
      this.selectedStoreName = text;
      const filterData = this.storeOptions.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text).toLowerCase()) > -1
        );
      });

      this.filteredStoreOptions = [...filterData];

      if (this.selectedStoreName === '') {
        this.filteredStoreOptions = [...this.storeOptions];
        this.selectedStoreId = null;
      }
      if (!this.selectedStoreName) {
        this.selectedStoreId = null;
      }
    },
    onSelectStores(option) {
      this.selectedStoreName = option.item.StoreName;
      this.selectedStoreId = option.item.id;
    },
    fetchStore: async function () {
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.storeOptions = stores.map((item) => {
          return {
            id: item.id,
            suggestionName: item.name,
            name: item.name,
          };
        });

        this.filteredStoreOptions = [...this.storeOptions];
      });
    },
    handleCheckReward() {
      if (!this.selectedProductId) {
        this.makeToastWarning('Vui lòng chọn sản phẩm!');
        return;
      }

      if (!this.selectedStoreId) {
        this.makeToastWarning('Vui lòng chọn cửa hàng!');
        return;
      }

      if (!this.selectedMonth) {
        this.makeToastWarning('Vui lòng chọn tháng năm!');
        return;
      }

      const momentDate = moment(this.selectedMonth, moment.ISO_8601, true);

      let month = momentDate.month() + 1;
      let year = momentDate.year();

      if (!momentDate.isValid()) {
        [month, year] = this.selectedMonth.split('/').map(Number);
      }

      const params = {
        productId: this.selectedProductId,
        storeId: this.selectedStoreId,
        month,
        year,
      };

      ApiService.query('rewardReport/check', { params })
        .then((response) => {
          this.rewardPrograms = response.data.data;
          this.renderListRewardProgram = true;
        })
        .catch((error) => {
          console.error(error);
          this.makeToastFailure('Lỗi khi kiểm tra hoa hồng!');
        });
    },
    handleViewDetailCategory(id) {
      const url = this.$router.resolve({
        name: 'update-reward-category',
        query: { id },
      }).href;
      window.open(url, '_blank');
    },
    handleViewDetailProgram(id) {
      const url = this.$router.resolve({
        name: 'update-reward-program',
        query: { id },
      }).href;
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped></style>
