<template>
  <div>
    <KTCodePreview v-bind:title="'In chính sách bảo hành'">
      <template v-slot:toolbar>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder"
          @click="onClickToPrint(printMode)"
        >
          <i style="font-size: 1rem" class="fas fa-print"></i>In hoá đơn
        </b-button>
      </template>
      <template v-slot:preview>
        <hr
          class="hr-text"
          data-content="Khổ giấy A4 - A5"
          style="font-weight: 600"
          v-show="printMode === 'A4'"
        />
        <div id="A4" v-if="renderComponent" v-show="printMode === 'A4'">
          <div
            v-for="(warrantyPackage, index) in bill.billItems"
            :key="index"
            class="page-break"
          >
            <BillPrintWarranyBenefitPolicyInfo
              :warrantyPackage="warrantyPackage"
              :bill="bill"
            />
          </div>
        </div>
        <br />
      </template>
    </KTCodePreview>
    <template>
      <BillPrintWarrantyBenefitPolicyModal
        ref="warranty-benefit-policy-bill-modal"
        :checkedItem="bill"
        @checkedProductPrint="checkedProductPrint"
      />
    </template>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { TIME_TRIGGER } from '@/utils/constants';
import BillPrintWarranyBenefitPolicyInfo from '@/view/components/bills/BillPrintWarranyBenefitPolicyInfo';
import BillPrintWarrantyBenefitPolicyModal from '@/view/components/bills/BillPrintWarrantyBenefitPolicyModal.vue';
import { makeToastFaile } from '@/utils/common';

export default {
  components: {
    KTCodePreview,
    BillPrintWarranyBenefitPolicyInfo,
    BillPrintWarrantyBenefitPolicyModal,
  },
  data() {
    return {
      bill: {
        billNumber: '',
        storeId: '',
        storeName: '',
        billItems: [],
      },
      renderComponent: false,
      printMode: 'A4',
      checkedWarrantyPackage: [],
    };
  },
  async created() {
    const defaultPrintMode = 'A4';
    this.printMode = defaultPrintMode;
    const billNumber = this.$route.query.id;
    await this.getBillById(billNumber);
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn bảo hành', route: '/warranty-bill' },
      { title: 'In hóa đơn bảo hành' },
    ]);
  },
  methods: {
    checkedProductPrint(listProductPrint) {
      this.bill = { ...this.bill, billItems: listProductPrint };
      this.checkedWarrantyPackage = listProductPrint;
      this.renderComponent = true;
    },
    getBillById: async function (billNumber) {
      try {
        const printFromOrder = this.$route.query.printFromOrder;
        const endPoint = `${
          printFromOrder ? 'orders' : 'bills'
        }/print-warranty-benefit-policy/${billNumber}`;
        const response = await ApiService.get(endPoint);
        const { data } = response.data;
        this.bill = data;
        this.$refs['warranty-benefit-policy-bill-modal'].showModal();
      } catch ({ response, message }) {
        const errorMessage =
          response?.data?.message ?? message ?? 'Network error';
        makeToastFaile(errorMessage);
      }
    },
    printData(mode, stylePrint) {
      const prtHtml = document.getElementById(mode).innerHTML;
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
            ${stylePrint}
          </head>
          <body`);
      WinPrint.document.write(`${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
      }, TIME_TRIGGER);
    },
    getStylePrint() {
      return `<style>
                  *{
                    font-family: "Roboto", Helvetica, "sans-serif", "Poppins";
                  }
                  .font-size-print {font-size: 20px !important; color: black; padding-top: 10px;}
                  .font-size-label-print{font-size: 30px !important; color: black;}
                  .font-size-gift {font-size: 18px !important; color: black;}
                  .font-size-warranty{ font-size: 20px !important; color: black;}
                  .font-size-product-info{ font-size: 20px !important; color: black;}
                  .item-print {
                      width: 100% !important;
                      margin-left: 0px !important;
                    }
                  .heading-logo {
                    object-fit: contain;
                    width: 100%;
                    max-width: 300px;
                  }
                  no-border-pre-wrap {
                    border: none;
                    box-shadow: none;
                    resize: none;
                    background-color: transparent;
                    outline: none;
                    white-space: pre-wrap;
                    padding-left: 0;
                    margin-left: 0;
                  }
                  .policy-label{
                    margin-bottom: 0px;
                    margin-top: 20px;
                  }
                  .policy-content{
                    font-size: 24px;
                    color: #000;
                    padding-left: 10px;
                    font-weight: 600;
                  }
                   .page-break {
                    page-break-before: always;
                  }
                  .title{
                    padding-bottom: 30px;
                    padding-top: 10px;
                  }
                  .benefit-content{
                    font-size: 26px;
                  }
                  .border{
                    border: 2px solid #000 !important;
                    padding-left: 30px;
                    border-radius: 10px;
                  }
                  .benfit-label{
                    margin-bottom: 20px;
                  }
                  .content-info {
                    color: black;
                    margin-top: 20px;
                  }
                  .qr-content{
                    margin-left: 55px;
                  }
                  .qr-text{
                    font-size: 20px !important;
                    color: #000;
                    font-weight: 500;
                    margin: 10px 0px;
                    margin-left: -70px !important;
                    }
                </style>`;
    },
    onClickToPrint(mode) {
      if (!this.checkedWarrantyPackage.length) {
        this.$refs['warranty-benefit-policy-bill-modal'].showModal();
        return;
      }
      const warrantyPackageWithoutExpiry = this.checkedWarrantyPackage.find(
        (warrantyPackage) => !warrantyPackage.warrantyExpiredDate,
      );
      if (warrantyPackageWithoutExpiry) {
        makeToastFaile(
          `Gói bảo hành ${warrantyPackageWithoutExpiry.productName} chưa cấu hình 'Số tháng bảo hành' vui lòng liên hệ ngành hàng để cập nhật`,
        );
        return;
      }
      this.printMode = mode;
      const stylePrint = this.getStylePrint();
      this.printData(mode, stylePrint);
    },
  },
};
</script>
<style scoped>
.page-break {
  page-break-before: always;
}
</style>
