import { removeAccents } from '@/utils/common';

export class StoreOption {
  constructor(data) {
    this.id = data.id;
    this.label = data.name;
    this.shortName = data.shortName;
    this.noAccentLabel = removeAccents(this.label);
  }
}
