<template>
    <div class="order-common">
        <b-modal
            id="md-change-status-excel"
            hide-footer
            title="Đổi trạng thái các đơn hàng:"
            no-close-on-backdrop
            size="lg"
        >
            <div class="mt-4">
                <div class="mb-4">
                    <b-row>
                        <b-col>
                            <div class="form-group">
                                <span>
                                    <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng
                                    format, hoặc có thể tải ở
                                    <a :href="urlExcel">đây</a>
                                </span>
                            </div>
                        </b-col>
                    </b-row>
                    <!-- Nhập file -->
                    <b-row>
                        <b-col cols="12">
                            <span> <b>Bước 2:</b> Nhập sản phẩm và tải lên </span>
                            <UploadFileMulter
                                v-on:send-file="getDataFromExcel"
                                v-on:clear-file="clearFileExcel"
                                :cmdUrl="'orders/import-status-excel'"
                            />
                            <span v-if="fileName"
                                >Tên file: <b>{{ fileName }}</b>
                            </span>
                        </b-col>
                    </b-row>
                </div>
                <b-row>
                    <b-col cols="6">
                        <b-form-group
                            label-cols-sm="4"
                            label-cols-lg="4"
                            label="Chọn trạng thái"
                            label-class="font-weight-bold"
                            class="mb-0"
                        >
                            <treeselect
                                :options="optionStatus"
                                :multiple="false"
                                placeholder="Nhập trạng thái:"
                                noResultsText="Không có kết quả"
                                :match-keys="['label', 'custom']"
                                v-model="statusParent"
                                openDirection="bottom"
                                :clearable="false"
                                @select="onSelectStatus"
                            >
                                <label
                                    slot="option-label"
                                    slot-scope="{ node, labelClassName }"
                                    :class="labelClassName"
                                >
                                    <span :title="node.label"> {{ node.label }}</span>
                                </label>
                            </treeselect>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            label-cols-sm="4"
                            label-cols-lg="4"
                            label="Lý do huỷ"
                            label-class="font-weight-bold"
                            class="mb-0"
                            v-if="htmlIsShown(statusParent, 'cancel-status')"
                        >
                            <treeselect
                                :options="optionCancelStatus"
                                :multiple="false"
                                placeholder="Nhập lý do:"
                                noResultsText="Không có kết quả"
                                :match-keys="['label', 'custom']"
                                v-model="statusCancelParent"
                                openDirection="bottom"
                                :clearable="false"
                                @select="onSelectCancelStatus"
                            >
                                <label
                                    slot="option-label"
                                    slot-scope="{ node, labelClassName }"
                                    :class="labelClassName"
                                >
                                    <span :title="node.label"> {{ node.label }}</span>
                                </label>
                            </treeselect>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-table
                    id="my-table"
                    class="table-common"
                    hover
                    bordered
                    :items="items"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                >
                    <template #cell(statusValidate)="data">
                        <div v-if="!data.item.onLoading" class="d-table-row">
                            <div class="d-table-cell align-middle" style="width: 10%">
                                <i
                                    v-b-tooltip.hover
                                    :title="htmlGetStatusValidate(data.item, 'title')"
                                    :class="htmlGetStatusValidate(data.item, 'class')"
                                ></i>
                            </div>
                            <div class="d-table-cell align-middle pb-1 pl-2" style="width: 90%">
                                {{ data.item.statusValidateName }}
                            </div>
                        </div>
                        <div v-else>
                            <b-spinner
                                small
                                label="Small Spinner"
                                variant="primary"
                                class="mr-1"
                            ></b-spinner>
                            <span class="text-primary">Đang xử lí...</span>
                        </div>
                    </template>
                    <template #cell(storeId)="data">
                        <span>
                            {{ data.item.storeName }}
                        </span>
                    </template>
                    <template #cell(status)="data">
                        <b-form-group class="mb-0 d-flex align-items-center">
                            <div class="d-table-row">
                                <div class="d-table-cell align-middle" style="width: 40%">
                                    <label class="font-weight-bold pt-1">
                                        {{ data.item.statusName }}
                                    </label>
                                </div>
                                <div class="d-table-cell align-middle" style="width: 10%">
                                    <i
                                        class="fa fa-arrow-right text-primary"
                                        aria-hidden="true"
                                    ></i>
                                </div>
                                <div class="d-table-cell align-middle pb-1" style="width: 55%">
                                    <treeselect
                                        :options="optionStatus"
                                        :multiple="false"
                                        placeholder="Chọn trạng thái"
                                        noResultsText="Không có kết quả"
                                        :match-keys="['label', 'custom']"
                                        v-model="data.item.toStatus"
                                        openDirection="bottom"
                                        :clearable="false"
                                        @select="onChangeStatus(data.item, $event)"
                                    >
                                        <label
                                            slot="option-label"
                                            slot-scope="{ node, labelClassName }"
                                            :class="labelClassName"
                                        >
                                            <span :title="node.label"> {{ node.label }}</span>
                                        </label>
                                    </treeselect>
                                    <span
                                        class="tree-select-custom"
                                        v-if="htmlIsShown(data.item.toStatus, 'cancel-status')"
                                    >
                                        <treeselect
                                            :options="optionCancelStatus"
                                            :multiple="false"
                                            placeholder="Chọn lý do huỷ"
                                            noResultsText="Không có kết quả"
                                            :match-keys="['label', 'custom']"
                                            v-model="data.item.cancelStatus"
                                            openDirection="bottom"
                                            :clearable="false"
                                        >
                                            <label
                                                slot="option-label"
                                                slot-scope="{ node, labelClassName }"
                                                :class="labelClassName"
                                            >
                                                <span :title="node.label"> {{ node.label }}</span>
                                            </label>
                                        </treeselect>
                                    </span>
                                </div>
                            </div>
                        </b-form-group>
                    </template>
                    <template #cell(actions)="row">
                        <v-icon
                            small
                            class="text-danger"
                            @click="onRemovedItem(row.item.id)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                        >
                    </template>
                </b-table>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    v-show="this.items.length > 5"
                    aria-controls="my-table"
                ></b-pagination>
                <vcl-table
                    v-if="onLoadingList"
                    :speed="5"
                    :animate="true"
                    :columns="10"
                    class="mb-4"
                ></vcl-table>
            </div>
            <b-overlay
                :show="onLoadingList || isBusy"
                rounded
                opacity="0.6"
                class="d-inline-block mr-3"
            >
                <template #overlay>
                    <div class="d-flex align-items-center">
                        <b-spinner variant="light" small type="grow"></b-spinner>
                    </div>
                </template>
                <b-button
                    :disabled="onLoadingList || !items.length"
                    variant="primary"
                    size="sm"
                    @click="onSaveOrderStatus"
                >
                    <strong>Cập nhật</strong>
                </b-button>
            </b-overlay>

            <b-button
                style="width: 80px"
                variant="secondary"
                size="sm"
                @click="hideModalChangeStatus()"
            >
                <strong>Hủy</strong>
            </b-button>
        </b-modal>
    </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import apiCommonMixin from '@/view/mixins/api-common.mixins.js';
import linkMixin from '@/view/mixins/link.mixins.js';
import { validationMixin } from 'vuelidate';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { mapGetters } from 'vuex';
import { SET_ORDER_STATUS_SELECTED } from '@/core/services/store/modules/order/order-seleted-status.module.js';
import {
    ORDER_STATUS,
    ORDER_CANCEL_STATUS,
    ORDER_CANCEL_STATUS_CR,
    ORDER_CANCEL_STATUS_SHOP,
    ORDER_CANCEL_STATUS_DP,
    ORDER_STATUS_CR,
    ORDER_STATUS_SHOP,
    ORDER_STATUS_DP,
} from '@/utils/constants';
import {
    removeAccents,
    cloneDeep,
    makeToastSuccess,
    makeToastFaile,
    checkJobTitle,
    checkGroupOrderStatus,
} from '@/utils/common';
import { VclTable } from 'vue-content-loading';
import { TIME_TRIGGER } from '@/utils/constants';
import { EventBus } from '@/core/services/event-bus';
import ENUM from '@/utils/enum';
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';
import { URL_IMPORT_ORDER_STATUS } from '../../../utils/constants';

const STATUS_VALIDATE = {
    INVALID: 0,
    VALID: 1,
    REQUIRED: 2,
};
const thStyleCommon = {
    textAlign: 'center',
    fontWeight: 600,
    color: '#181c32',
    width: '5%',
};

export default {
    mixins: [validationMixin, apiCommonMixin, linkMixin],
    props: {
        userInfor: Object,
        param: Object,
        mode: String,
    },
    components: {
        Treeselect,
        VclTable,
        UploadFileMulter,
    },
    data() {
        return {
            fields: [],
            defaultFields: [
                {
                    key: 'id',
                    label: 'ID',
                    thStyle: {
                        ...thStyleCommon,
                        width: '5%',
                    },
                    formatter: (value) => {
                        return `#${value}`;
                    },
                    tdClass: 'align-middle',
                },
                {
                    key: 'storeId',
                    label: 'Cửa hàng',
                    thStyle: {
                        ...thStyleCommon,
                        width: '20%',
                    },
                    tdClass: 'text-center align-middle',
                },
                {
                    key: 'status',
                    label: 'Đổi trạng thái',
                    thStyle: {
                        ...thStyleCommon,
                        width: '50%',
                    },
                    tdClass: 'align-middle',
                },
                {
                    key: 'statusValidate',
                    label: 'Kiểm tra',
                    thStyle: {
                        ...thStyleCommon,
                        width: '28%',
                    },
                    tdClass: 'align-middle',
                },
                {
                    key: 'actions',
                    label: '',
                    tdClass: 'align-middle',
                },
            ],
            items: [],
            itemsTerm: [],
            optionCancelStatus: [],
            optionStatus: [],
            onLoadingList: false,
            isBusy: false,
            statusParent: null,
            statusCancelParent: null,
            changingStatusStores: [],
            isSelectedStore: null,
            currentPage: 1,
            perPage: 5,
            urlExcel: URL_IMPORT_ORDER_STATUS,
            fileName: '',
            file: null,
        };
    },
    watch: {
        getOrderStatus: {
            handler(value) {
                this.itemsTerm = value;
            },
            deep: true,
        },
        $props: {
            handler() {
                switch (this.mode) {
                    case 'changeStatus':
                        this.selectedStatus = null;
                        this.selectedCancelStatus = null;
                        break;
                    default:
                        break;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.initConstants();
    },
    mounted() {
        EventBus.$on('popup-common-order-excel', this.popupOrderCommon);
    },
    beforeDestroy() {
        EventBus.$off('popup-common-order-excel', this.popupOrderCommon);
    },
    computed: {
        ...mapGetters(['getOrderStatus']),
        rows() {
            return this.items.length;
        },
    },
    methods: {
        initConstants() {
            const userInfor = this.userInfor;
            this.fields = this.defaultFields;

            let cancelStatus = [];
            let status = [];
            const jobTitleId = userInfor.jobTitleId;

            if (checkJobTitle().isCR(jobTitleId)) {
                cancelStatus = ORDER_CANCEL_STATUS_CR;
                status = ORDER_STATUS_CR;
            } else if (checkJobTitle().isShop(jobTitleId)) {
                cancelStatus = ORDER_CANCEL_STATUS_SHOP;
                status = ORDER_STATUS_SHOP;
            } else if (checkJobTitle().isDP(jobTitleId)) {
                cancelStatus = ORDER_CANCEL_STATUS_DP;
                status = ORDER_STATUS_DP;
            } else {
                cancelStatus = ORDER_CANCEL_STATUS;
                status = ORDER_STATUS;
            }

            this.optionCancelStatus = cloneDeep(cancelStatus).reduce((result, element) => {
                result.push({
                    id: Number(element.value),
                    label: element.text,
                    custom: removeAccents(element.text),
                    customLabel: element.text,
                });
                return result;
            }, []);

            this.optionStatus = status.reduce((result, element) => {
                result.push({
                    id: element.value ? Number(element.value) : -1,
                    label: element.text,
                    custom: removeAccents(element.text),
                    customLabel: element.text,
                });
                return result;
            }, []);
        },
        htmlIsShown(value, name) {
            let check = false;
            switch (name) {
                case 'cancel-status': {
                    const status = [
                        ENUM.ORDER_STATUS.UN_SUCCESS,
                        ENUM.ORDER_STATUS.SYSTEM_CANCEL,
                        ENUM.ORDER_STATUS.CR_CANCEL,
                    ];

                    if (status.includes(value)) {
                        check = true;
                    }

                    break;
                }
            }
            return check;
        },
        htmlRowClass(item, type) {
            if (!item || type !== 'row') return;

            if (this.userInfor.storeId !== item.storeId) {
                return 'table-danger';
            }
        },
        htmlGetStatusValidate(item, type) {
            let name = '';
            switch (item.statusValidate) {
                case STATUS_VALIDATE.VALID: {
                    name = type === 'class' ? 'fas fa-check-circle text-success' : 'Hợp lệ';

                    break;
                }
                case STATUS_VALIDATE.REQUIRED: {
                    name =
                        type === 'class' ? 'fas fa-check-circle text-warning' : 'Chưa chọn lí do';
                    break;
                }
                default: {
                    name = type === 'class' ? 'fas fa-times-circle text-danger' : 'Không hợp lệ';
                    break;
                }
            }
            return name;
        },
        onSelectStatus(node) {
            this.isSelectedStore = false;
            const statusChanged = node.id;
            this.items.map((item) => {
                const [statusValidate, statusValidateName] = this.validationStoreStatus(
                    item.status,
                    statusChanged,
                );
                item.toStatus = node.id;
                item.toStatusName = node.label;
                item.statusValidate = statusValidate;
                item.statusValidateName = statusValidateName;
            });
        },
        onChangeStatus(storeSelected, event) {
            // Check if user change status of all stores with only 1 select
            // but then user wanna changes status of each store
            if (!this.isSelectedStore) {
                const newListStores = [...this.items];
                const isExistStore = newListStores.find((store) => store.id === storeSelected.id);
                const currentStatus = isExistStore.status;
                const newStatus = event.id;
                const [statusValidate, statusValidateName] = this.validationStoreStatus(
                    currentStatus,
                    newStatus,
                );
                isExistStore.toStatus = event.id;
                isExistStore.toStatusName = event.label;
                isExistStore.statusValidate = statusValidate;
                isExistStore.statusValidateName = statusValidateName;
            }
            // Check if user changes status of each store
            else {
                this.isSelectedStore = true;
                this.changingStatusStores.push(storeSelected);
                const newListChangingStores = [...this.changingStatusStores];
                const isExistStore = newListChangingStores.find(
                    (store) => store.id === storeSelected.id,
                );
                const currentStatus = isExistStore.status;
                const newStatus = event.id;
                const [statusValidate, statusValidateName] = this.validationStoreStatus(
                    currentStatus,
                    newStatus,
                );
                isExistStore.toStatus = event.id;
                isExistStore.toStatusName = event.label;
                isExistStore.statusValidate = statusValidate;
                isExistStore.statusValidateName = statusValidateName;
            }
        },
        getUniqueList(arr, key) {
            // NOTE: item in under function is store
            return [...new Map(arr.map((item) => [item[key], item])).values()];
        },
        hideModalChangeStatus() {
            this.$bvModal.hide('md-change-status-excel');
            this.changingStatusStores = [];
            this.items = [];
            this.isSelectedStore = null;
        },
        validationStoreStatus(statusChild, statusChanged) {
            let statusValidate = STATUS_VALIDATE.VALID;
            let statusValidateName = 'Hợp lệ';

            if (statusChanged === -1) {
                statusValidate = STATUS_VALIDATE.INVALID;
                statusValidateName = 'Chưa chọn';
                return [statusValidate, statusValidateName];
            }

            try {
                const changedGroup = checkGroupOrderStatus().getByGroup(statusChanged);
                const sourceGroup = checkGroupOrderStatus().getByGroup(statusChild);
                const checkStatus = checkGroupOrderStatus().valid(sourceGroup, changedGroup);

                if (!checkStatus) {
                    statusValidate = STATUS_VALIDATE.INVALID;
                    statusValidateName = 'Không hợp lệ';
                }
            } catch (error) {
                makeToastFaile(error.message);
                statusValidate = STATUS_VALIDATE.INVALID;
                statusValidateName = error.message;
            }

            return [statusValidate, statusValidateName];
        },
        onSelectCancelStatus(node) {
            this.items.map((item) => {
                item.cancelStatus = node.id;
                item.cancelStatusName = node.label;
            });
        },
        onRemovedItem(itemRemoveId) {
            const findIndexBy = (items, itemRemoveId) => {
                return items.findIndex((item) => item.id === itemRemoveId);
            };

            const index = findIndexBy(this.items, itemRemoveId);
            if (index > -1) {
                this.items.splice(index, 1);
                this.$store.dispatch(SET_ORDER_STATUS_SELECTED, this.items);
                EventBus.$emit('re-seletect-order', this.items);
            }
        },
        onSaveOrderStatus: async function () {
            const payloadExcel = [];
            const isSelectedStore = this.isSelectedStore;
            const uniqueListStores = this.getUniqueList(this.changingStatusStores, 'id');
            const items = isSelectedStore ? cloneDeep(uniqueListStores) : cloneDeep(this.items);
            for (const item of items) {
                const param = {
                    id: item.id,
                    status: item.toStatus,
                    storeId: item.storeId,
                    cancelStatus: item.cancelStatus,
                };
                payloadExcel.push(param);
            }
            // NOTE: invalids means a list of store that's fail the validate when changing from current status to new status
            const invalids = items.filter((item) => !item.statusValidate);

            if (invalids.length) {
                return makeToastFaile(
                    `Đơn hàng không hợp lệ ${invalids.map((item) => item.id).join()}`,
                );
            }

            if (!this.isBusy) {
                this.isBusy = true;
                const dataMessages = {
                    success: [],
                    failed: [],
                };
                const [status, message, data] = await ApiService.put(
                    `/orders/status/excel`,
                    payloadExcel,
                )
                    .then((response) => {
                        const { status, message, data } = response.data;
                        return [status, message, data];
                    })
                    .catch((err) => {
                        this.$nprogress.done();
                        if (!err.response) {
                            // network error
                            return [0, 'Lỗi mạng'];
                        } else {
                            const message = err.response.data.message;
                            return [0, message];
                        }
                    });
                if (status) {
                    dataMessages.success = data.successes;
                    dataMessages.failed = data.failures;
                } else {
                    return makeToastFaile(message);
                }

                this.isBusy = false;
                if (dataMessages.success.length === items.length) {
                    this.items = [];
                    makeToastSuccess('Cập nhật tất cả trạng thái đơn hàng thành công');

                    this.$bvModal.hide('md-change-status-excel');
                    this.fileName = '';
                    this.changingStatusStores = [];
                    this.isSelectedStore = null;
                    this.$emit('refresh', true);
                } else {
                    const ids = dataMessages.failed.map(entry => entry.item.id);
                    this.items = items.filter(item => ids.includes(item.id));
                    this.fileName = '';
                    this.changingStatusStores = [];
                    this.isSelectedStore = null;
                    makeToastFaile(`Cập nhật các đơn hàng với id ${ids} thất bại!`);
                }
            }
        },
        getOrderByIds(orders) {
            this.onLoadingList = true;
            this.items = [];
            const orderIds = orders.map((order) => order.id);

            ApiService.post(`/orders/status/ids`, {
                orderIds,
            })
                .then((response) => {
                    const { data } = response.data;
                    const { status, message } = response.data;

                    if (status) {
                        setTimeout(() => {
                            this.items = data;
                            this.onLoadingList = false;
                        }, TIME_TRIGGER);
                    } else {
                        makeToastFaile(message);
                        this.onLoadingList = false;
                    }
                })
                .catch((err) => {
                    if (!err.response) {
                        // network error
                        makeToastFaile('Lỗi!');
                    } else {
                        const message = err.response.data.message;
                        makeToastFaile(message);
                    }
                    this.onLoadingList = false;
                    this.$nprogress.done();
                });
        },
        popupOrderCommon() {
            // if (!this.itemsTerm.length) {
            //   makeToastFaile('Vui lòng chọn lại đơn hàng');
            //   this.$store.dispatch(SET_ORDER_STATUS_SELECTED, this.items);
            //   EventBus.$emit('re-seletect-order', this.items);
            //   return;
            // }

            this.$bvModal.show('md-change-status-excel');

            this.statusParent = null;
            this.statusCancelParent = null;
            // this.getOrderByIds(this.itemsTerm);
        },
        getDataFromExcel(data, fileName) {
            this.fileName = fileName;
            const orderIds = data.rows.reduce((acc, item) => {
                if (item.isValid) {
                    acc.push(item.orderCode); // Thêm orderCode hợp lệ vào mảng
                }
                return acc;
            }, []);
            const orderIdsError = data.rows.filter((item) => !item.isValid);

            ApiService.post(`/orders/status/ids`, {
                orderIds,
            })
                .then((response) => {
                    const { data } = response.data;
                    const { status, message } = response.data;

                    if (status) {
                        setTimeout(() => {
                            this.items = data;
                            orderIdsError.forEach((order) => {
                                this.items.push({
                                    id: order.orderCode,
                                    storeId: null,
                                    storeName: null,
                                    status: null,
                                    statusName: null,
                                    cancelStatus: null,
                                    cancelStatusName: null,
                                    toStatus: null,
                                    toStatusName: null,
                                    statusValidate: 0,
                                    statusValidateName: order.errors.join(', '),
                                    onLoading: false,
                                });
                            });
                            this.onLoadingList = false;
                        }, TIME_TRIGGER);
                    } else {
                        makeToastFaile(message);
                        this.onLoadingList = false;
                    }
                })
                .catch((err) => {
                    if (!err.response) {
                        // network error
                        makeToastFaile('Lỗi!');
                    } else {
                        const message = err.response.data.message;
                        makeToastFaile(message);
                    }
                    this.onLoadingList = false;
                    this.$nprogress.done();
                });

            // const inValidItems = this.importItems.filter(
            //   (importItem) => !importItem.isValid,
            // );
            // this.isInValid = !!inValidItems.length;
        },
        clearFileExcel() {
            this.items = [];
            this.fileName = '';
        },
    },
};
</script>

<style scoped>
.table-common /deep/ .vue-treeselect__input-container {
    padding-top: 0px;
}

.table-common /deep/ .vue-treeselect__control {
    border-radius: none;
    border: none;
    height: 2.5rem;
}

.table-common /deep/ .vue-treeselect__input {
    font-size: 22px;
}
</style>
