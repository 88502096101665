<template>
  <div class="add-transfer-stock">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-row class="pl-0">
          <b-col cols="6">
            <b-form-group class="required-control">
              <label>Tên chính sách gói bảo hành: </label>
              <b-form-input
                size="sm"
                v-model="mainModel.name"
                aria-describedby="input-fullName-live-feedback"
                placeholder="Nhập tên gói bảo hành"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group class="required-control">
              <label>Loại chính sách bảo hành: </label>

              <b-form-select
                size="sm"
                v-model="mainModel.type"
                :options="warrantyBenefitPolicyType"
                value-field="id"
                text-field="name"
                class="select-style textarea-style"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >-- Chọn loại chính sách bảo hành --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="12">
            <b-form-group class="required-control">
              <label>Quyền lợi gói bảo hành:</label>
              <b-form-textarea
                size="lg"
                rows="7"
                v-model="mainModel.warrantyBenefit"
                aria-describedby="input-fullName-live-feedback"
                placeholder="Nhập quyền lợi gói bảo hành..."
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="12">
            <b-form-group class="required-control">
              <label>Chính sách gói bảo hành:</label>
              <b-form-textarea
                size="lg"
                rows="7"
                v-model.trim="mainModel.warrantyPolicy"
                aria-describedby="input-fullName-live-feedback"
                placeholder="Nhập chính sách gói bảo hành..."
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="12">
            <b-form-group class="required-control">
              <label>Đường dẫn đến website:</label>
              <b-form-textarea
                size="md"
                rows="1"
                v-model.trim="mainModel.link"
                aria-describedby="input-fullName-live-feedback"
                placeholder="Nhập đường dẫn đến website"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template v-slot:foot>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          v-if="
            action &&
            !isBusy &&
            checkPermission('WARRANTY_BENEFIT_POLICY_CREATE')
          "
          @click="onSubmitDebounce()"
        >
          {{ actionName }}
        </b-button>
        <b-button
          v-if="isBusy"
          variant="primary"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          disabled
        >
          <b-spinner small type="grow"></b-spinner>
          {{ actionName }}...
        </b-button>
        <b-button
          variant="secondary"
          size="sm"
          class="btn-size font-weight-600"
          @click="goBack()"
          >Trở về</b-button
        >
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { TIME_TRIGGER } from '@/utils/constants';
import { makeToastFaile } from '@/utils/common';
import { makeToastSuccess } from '@/utils/common';
import { STATUS_CODE } from '@/utils/enum';
import debounce from 'lodash/debounce';
import { cmdUrl } from '@/utils/apiUrl';

export default {
  data() {
    return {
      actionName: 'Lưu',
      action: null,
      title: 'Tạo mới chính sách bảo hành',
      mainModel: {
        id: null,
        name: '',
        type: null,
        warrantyPolicy: '',
        warrantyBenefit: '',
        link: null,
      },
      isBusy: false,
      warrantyBenefitPolicyType: [],
    };
  },
  components: {
    KTCodePreview,
  },
  async created() {
    const { id, action } = this.$route.query;
    this.action = action;
    const promises = [this.fetchWarrantyBenefitPolicyType()];
    if (id) {
      this.mainModel.id = id;
      promises.push(this.getWarrantyBenefitPolicyInfo());
    }
    this.title = this.getTitleByAction(this.action);
    await Promise.all(promises);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  methods: {
    getTitleByAction(action) {
      const actionMap = {
        create: 'Tạo mới chính sách bảo hành',
        update: 'Cập nhật chính sách bảo hành',
        view: 'Chi tiết chính sách bảo hành',
        default: 'Chính sách bảo hành mở rộng',
      };
      return actionMap[action] || actionMap['default'];
    },
    goBack() {
      this.$router.go(-1);
    },
    fetchWarrantyBenefitPolicyType() {
      ApiService.get(cmdUrl.warrantyBenefitPolicy.listType).then(({ data }) => {
        this.warrantyBenefitPolicyType = data.data;
      });
    },
    async getWarrantyBenefitPolicyInfo() {
      try {
        const endPoint = cmdUrl.warrantyBenefitPolicy.byId.replace(
          '{id}',
          this.mainModel.id,
        );
        const { data } = await ApiService.get(endPoint);
        this.mainModel = data?.data;
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      }
    },
    createWarrantyBenefitPolicy(body) {
      return ApiService.post(cmdUrl.warrantyBenefitPolicy.root, body);
    },
    updateWarrantyBenfitPolicy(body) {
      const endPoint = cmdUrl.warrantyBenefitPolicy.byId.replace(
        '{id}',
        this.mainModel.id,
      );
      return ApiService.patch(endPoint, body);
    },
    dispatchWarrantyBenefitPolicy(action, body) {
      switch (action) {
        case 'create':
          return this.createWarrantyBenefitPolicy(body);
        case 'update':
          return this.updateWarrantyBenfitPolicy(body);
        default:
          throw new Error(`Action: '${action}' không hợp lệ!`);
      }
    },
    onSubmitDebounce: debounce(function () {
      this.onSubmit();
    }, TIME_TRIGGER),
    async onSubmit() {
      if (this.isBusy) return;
      this.isBusy = true;
      try {
        const result = await this.dispatchWarrantyBenefitPolicy(
          this.action,
          this.mainModel,
        );
        if (result.data?.status === STATUS_CODE.SUCCESS) {
          makeToastSuccess(result.data.message);
          this.goBack();
        }
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      } finally {
        this.isBusy = false;
      }
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
<style scoped>
.font-weight-600 {
  font-weight: 600;
}

.btn-size {
  width: 90px;
}
.textarea-style {
  border: none;
}
</style>
