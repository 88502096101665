<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin voucher'">
      <template v-slot:toolbar>
        <div class="row" v-if="checkPermission('COUPON_UPDATE')">
          <div>
            <b-row
              class="mb-4 ml-4"
              style="vertical-align: middle; display: inline"
            >
              <div class="custom-control custom-switch" v-if="mainModel.id">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitch2"
                  v-model="mainModel.isEditable"
                  @change="onChangeEditable"
                />
                <label
                  class="custom-control-label text-lable"
                  for="customSwitch2"
                  style="font-size: 14px; font-weight: 500"
                  >Mở/ Khóa chương trình</label
                >
              </div>
            </b-row>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-col>
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    class="required-control"
                    :disabled="!isEditable()"
                  >
                    <label class="font-weight-bold">Tên chương trình</label>
                    <b-form-input
                      size="sm"
                      v-model="$v.mainModel.name.$model"
                      placeholder="Nhập tên"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group :disabled="!isEditable()">
                    <label class="font-weight-bold">Mô tả</label>
                    <b-form-textarea
                      v-model="mainModel.description"
                      placeholder="Nhập mô tả"
                      rows="5"
                      :max-length="255"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" v-if="mainModel.applyChanel !== 3">
                  <b-form-group>
                    <label class="font-weight-bold"
                      >Cửa hàng áp dụng (để trống nếu áp dụng toàn hệ
                      thống)</label
                    >
                    <b-input-group>
                      <vue-multi-select
                        style="margin-bottom: 2rem"
                        v-model="mainModel.storeIds"
                        :options="stores"
                        placeholder="Chọn cửa hàng"
                        option-label="name"
                      ></vue-multi-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    class="required-control"
                    :disabled="!isEditable()"
                  >
                    <label class="font-weight-bold">Từ ngày</label>
                    <date-picker
                      placeholder="Từ ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="mainModel.startDate"
                    ></date-picker>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    class="required-control"
                    :disabled="!isEditable()"
                  >
                    <label class="font-weight-bold">Đến ngày</label>
                    <date-picker
                      placeholder="Đến ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="mainModel.endDate"
                    ></date-picker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group :disabled="!isEditable()">
                    <label class="font-weight-bold">Giá trị voucher</label>
                    <b-row>
                      <b-col md="6" class="pr-0">
                        <b-form-select
                          size="sm"
                          v-model="mainModel.type"
                          :options="listType"
                          value-field="id"
                          text-field="name"
                          @change="validateVoucherValue()"
                          class="select-style"
                        ></b-form-select>
                      </b-col>
                      <b-col md="6" class="pl-0">
                        <BInputNumber
                          size="sm"
                          v-model="mainModel.value"
                          placeholder="Nhập giá trị voucher"
                          class="text-right"
                        />
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group :disabled="true">
                    <label class="font-weight-bold">Trạng thái</label>
                    <b-form-select
                      class="select-style"
                      size="sm"
                      v-model="mainModel.isActive"
                      :options="listStatus"
                      value-field="id"
                      text-field="name"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group :disabled="!isEditable()">
                    <label class="font-weight-bold"
                      >Giá trị giảm giá tối đa</label
                    >
                    <BInputNumber
                      size="sm"
                      v-model="mainModel.maxValue"
                      placeholder="Nhập giá trị giảm giá tối đa"
                      class="text-right"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <label class="font-weight-bold"
                      >Áp dụng giá trị tích lũy</label
                    >
                    <br />
                    <b-row class="col-md-12">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="showVoucherCumulativeConfigModal()"
                        v-b-tooltip.hover.top
                        title="Cấu hình giá trị tích lũy"
                      >
                        <b-icon icon="tools"></b-icon>
                      </b-button>

                      <VoucherCumulativeValue
                        class="ml-2"
                        v-if="mainModel.cumluativeValues.length"
                        :cumluativeValues="mainModel.cumluativeValues"
                      />
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group :disabled="!isEditable()">
                    <label class="font-weight-bold">Kênh áp dụng</label>
                    <b-form-select
                      size="sm"
                      v-model="mainModel.applyChanel"
                      :options="listChanel"
                      value-field="id"
                      text-field="name"
                      class="select-style"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group :disabled="!isEditable()">
                    <label class="font-weight-bold"
                      >Áp dụng cho sản phẩm có tổng tiền từ</label
                    >
                    <BInputNumber
                      size="sm"
                      v-model="mainModel.applyFromAmount"
                      placeholder="Nhập giá trị sản phẩm tối thiểu"
                      class="text-right"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr
            class="hr-text font-weight-bold"
            data-content="Danh sách voucher"
          />
          <b-button
            size="sm"
            variant="primary"
            class="font-weight-bolder mb-4"
            @click="addVoucherCode"
          >
            <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
          </b-button>
          <b-button
            @click="importExcel"
            size="sm"
            variant="secondary"
            class="font-weight-bolder mb-4 ml-4"
            v-if="!mainModel.id"
          >
            <i style="font-size: 1rem" class="far fa-file-excel"></i>Nhập Voucher từ
            Excel
          </b-button>
          <b-table
            hover
            bordered
            class="col-md-6"
            :fields="fieldDetail"
            :items="mainModel.details"
            v-if="renderComponent"
            :per-page="10"
            :current-page="currentPageDetail"
            id="my-table-detail"
          >
            <template v-slot:cell(code)="row">
              <b-input
                type="text"
                size="sm"
                class="input-style"
                v-model="row.item.code"
                :disabled="row.item.used !== 0 || !isEditable()"
              ></b-input>
            </template>
            <template v-slot:cell(maxUse)="row">
              <BInputNumber
                size="sm"
                v-model="row.item.maxUse"
                placeholder="Nhập giá trị giảm giá tối đa"
                class="text-right"
                :disabled="!isEditable()"
              />
            </template>
            <template v-slot:cell(actions)="row">
              <v-icon
                small
                class="text-danger text-center"
                @click="
                  showDeleteAlert(
                    alertText.title.voucherDetail,
                    alertText.description.voucherDetail,
                    row.item,
                    confirmDeleteVoucherDetail,
                  )
                "
                v-b-tooltip
                title="Xóa"
                :disabled="row.item.used !== 0 || !isEditable()"
                >mdi-delete</v-icon
              >
            </template>
          </b-table>
          <b-pagination
            v-show="mainModel.details.length > 10"
            v-model="currentPageDetail"
            :total-rows="mainModel.details.length"
            :per-page="10"
            aria-controls="my-table-detail"
            align="right"
            class="col-md-6 pr-0"
          ></b-pagination>
          <hr class="hr-text font-weight-bold" data-content="Áp dụng cho" />
          <b-row>
            <b-form-group class="col-md-12 pr-0">
              <label class="font-weight-bold">Sản phẩm:</label>
              <b-col class="row pr-0">
                <b-col md="6" class="p-0">
                  <Autosuggest
                    :model="searchProduct"
                    :suggestions="optionItems"
                    placeholder="tên, mã, mã vạch sản phẩm"
                    :limit="10"
                    @select="onSelectedItem"
                    @change="onInputChangeSearch"
                    :disabled="false"
                    suggestionName="name"
                  />
                </b-col>
                <b-col md="2" class="p-0">
                  <b-form-select
                    class="select-style border-left-0 rounded-left-0 rounded-right-0"
                    v-model="selectTypeSearch"
                    :options="[
                      {
                        id: 2,
                        name: 'Sản phẩm',
                      },
                      {
                        id: 3,
                        name: 'Danh mục sản phẩm',
                      },
                      {
                        id: 4,
                        name: 'Thương hiệu',
                      },
                    ]"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    @change="debounceInputSearch"
                  ></b-form-select>
                </b-col>
                <b-col md="2" class="p-0">
                  <b-form-select
                    class="select-style border-left-0 rounded-left-0"
                    v-model="selectTypeOfProduct"
                    :options="itemTypeOptions"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    @change="debounceInputSearch"
                  ></b-form-select>
                </b-col>
                <b-col md="2" class="pr-0">
                  <div class="d-flex justify-content-end">
                    <b-button
                      @click="showModalImportProduct"
                      size="sm"
                      variant="secondary"
                      class="font-weight-bolder"
                    >
                      <i style="font-size: 1rem" class="far fa-file-excel"></i
                      >Nhập SP từ excel
                    </b-button>
                  </div>
                </b-col>
              </b-col>
            </b-form-group>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                class="col-md-8"
                bordered
                hover
                :fields="fieldApplyItem"
                :items="mainModel.applyItems"
                v-if="renderComponent"
                :per-page="10"
                :current-page="currentPageTable"
                id="my-table-product"
              >
                <template v-slot:cell(referenceType)="row">
                  <span v-if="row.item.referenceType === 1">Sản phẩm</span>
                  <span v-if="row.item.referenceType === 2">Danh mục</span>
                  <span v-if="row.item.referenceType === 3">Thương hiệu</span>
                </template>
                <template v-slot:cell(actions)="row">
                  <v-icon
                    small
                    class="text-danger text-center"
                    @click="
                      showDeleteAlert(
                        alertText.title.voucherApplyItem,
                        alertText.description.voucherApplyItem,
                        row.item,
                        confirmDeleteApplyItem,
                      )
                    "
                    v-b-tooltip
                    title="Xóa"
                    >mdi-delete</v-icon
                  >
                </template>
              </b-table>
              <b-pagination
                v-show="mainModel.applyItems.length > 10"
                v-model="currentPageTable"
                :total-rows="mainModel.applyItems.length"
                :per-page="10"
                aria-controls="my-table-product"
                align="right"
                class="col-md-8 pr-0"
              ></b-pagination>
            </b-col>
          </b-row>
          <b-row>
            <b-form-group class="col-md-12 pr-0">
              <label class="font-weight-bold">Khách hàng:</label>
              <b-col class="row pr-0">
                <b-col md="6" class="p-0">
                  <Autosuggest
                    :model="customerPhone"
                    :suggestions="filteredOptionsCustomer"
                    placeholder="khách hàng"
                    :limit="10"
                    @select="onSelectedCustomer"
                    @change="onSearchCustomerChange"
                    :disabled="false"
                    suggestionName="name"
                  >
                    <template #custom="{ suggestion }">
                      <div>
                        <div class="d-flex flex-column">
                          <span
                            class="text-primary mb-1"
                            v-if="suggestion.item.customerMobile"
                            >({{ suggestion.item.customerMobile }})</span
                          >
                          <span v-if="suggestion.item.name">{{
                            suggestion.item.name
                          }}</span>
                          <span v-else>{{ suggestion.item.message }}</span>
                        </div>
                      </div>
                    </template>
                  </Autosuggest>
                </b-col>
                <b-col md="2" class="p-0">
                  <b-form-select
                    class="select-style border-left-0 rounded-left-0 rounded-right-0"
                    v-model="searchCustomerType"
                    :options="[
                      {
                        id: 1,
                        name: 'Loại khách',
                      },
                      {
                        id: 2,
                        name: 'SĐT',
                      },
                    ]"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    @change="debounceInputCustomer"
                  ></b-form-select>
                </b-col>
              </b-col>
            </b-form-group>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                class="col-md-8"
                bordered
                hover
                :fields="fieldCustomers"
                :items="mainModel.applyCustomers"
                v-if="renderComponent"
                :per-page="10"
                :current-page="currentPageTable"
                id="my-table-customer"
              >
                <template v-slot:cell(referenceType)="row">
                  <span v-if="row.item.referenceType === 1"
                    >Loại khách hàng</span
                  >
                  <span v-if="row.item.referenceType === 2">SĐT</span>
                </template>
                <template v-slot:cell(name)="row">
                  <div v-if="row.item.referenceType === 1">
                    <span v-text="row.item.name" />
                  </div>
                  <div v-if="row.item.referenceType === 2" class="row">
                    <span class="ml-4">{{ row.item.customerMobile }}</span>
                    <span class="text-primary" v-if="row.item.name">
                      &nbsp;- {{ row.item.name }}
                    </span>
                  </div>
                </template>

                <template v-slot:cell(actions)="row">
                  <v-icon
                    small
                    class="text-danger text-center"
                    @click="
                      showDeleteAlert(
                        alertText.title.voucherApplyCustomer,
                        alertText.description.voucherApplyCustomer,
                        row.item,
                        confirmDeleteApplyCustomer,
                      )
                    "
                    v-b-tooltip
                    title="Xóa"
                    >mdi-delete</v-icon
                  >
                </template>
              </b-table>
              <b-pagination
                v-show="mainModel.applyCustomers.length > 10"
                v-model="currentPageTable"
                :total-rows="mainModel.applyCustomers.length"
                :per-page="10"
                aria-controls="my-table-customer"
                align="right"
                class="col-md-8 pr-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </template>
      <template v-slot:foot>
        <!-- <b-col lg="4" class="pb-2"> -->
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
              v-if="checkPermission('COUPON_UPDATE') && isEditable()"
              >Lưu</b-button
            >
            <b-button
              :style="
                checkPermission('COUPON_UPDATE')
                  ? 'margin-left: 10px; font-weight: 600; width: 70px'
                  : 'font-weight: 600; width: 70px'
              "
              variant="secondary"
              size="sm"
              @click="onBack"
              >Hủy</b-button
            >
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
    <ImportExcelModal v-on:on-receive-code="onRecevieVoucherCode" />
    <ImportExcelProduct v-on:on-receive-product="onRecevieVoucherProduct" />
    <VoucherCumulativeModal
      ref="voucher-cumluative-modal"
      v-on:receive-cumulative="onRecevieCumulativeValue"
    />
  </div>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import _ from 'lodash';
import {
  cloneDeep,
  currencyMask,
  makeToastFaile,
  makeToastSuccess,
  unMaskPrice,
} from '@/utils/common';
import VueMultiSelect from 'vue-simple-multi-select';
import decounce from 'debounce';
import {
  LIST_CHANEL,
  PRODUCT_RELATIONSHIP_TYPES,
  TIME_TRIGGER,
  CUSTOMER_TYPE,
  ACTIVE_STATUS_LIST,
} from '@/utils/constants';
import { v4 } from 'uuid';
import localData from '@/utils/saveDataToLocal';
import { AMOUNT_TYPE } from '@/utils/enum';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import timeUtils from '@/utils/date';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import ImportExcelModal from '@/view/components/voucher/ImportExcelModal.vue';
import VoucherCumulativeModal from '@/view/components/voucher/VoucherCumulativeModal.vue';
import VoucherCumulativeValue from '@/view/components/voucher/VoucherCumulativeValue.vue';
import ImportExcelProduct from '@/view/components/voucher/ImportExcelProduct.vue';

const SEARCH_TYPE = {
  PRODUCT: 2,
  CATEGORY: 3,
  BRAND: 4,
};

export default {
  mixins: [validationMixin],
  data() {
    return {
      renderComponent: true,
      dpConfigs: timeUtils.DP_CONFIG,
      mask: currencyMask,
      originProgramStatus: null,
      mainModel: {
        id: 0,
        name: '',
        description: '',
        startDate: new Date(),
        endDate: null,
        details: [],
        storeIds: [],
        isActive: 1,
        type: 1,
        value: 0,
        maxValue: 0,
        applyFromAmount: 0,
        listProduct: [],
        applyChanel: 1,
        applyCustomers: [],
        applyItems: [],
        cumluativeValues: [],
        isEditable: true,
      },
      stores: [],
      listStatus: ACTIVE_STATUS_LIST,
      listType: [
        {
          id: 1,
          name: 'Tiền mặt $',
        },
        {
          id: 2,
          name: 'Chiết khấu %',
        },
      ],
      selectTypeSearch: 2,
      selectTypeOfProduct: 0,
      isSearching: false,
      currentPage: 1,
      currentPageTable: 1,
      currentPageDetail: 1,
      fieldDetail: [
        {
          key: 'code',
          label: 'Mã',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '45%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'maxUse',
          label: 'Số lần sử dụng',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'used',
          label: 'Đã sử dụng',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      fieldApplyItem: [
        {
          key: 'referenceType',
          label: 'Loại',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'code',
          label: 'Mã',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'name',
          label: 'Tên',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      listChanel: LIST_CHANEL,
      messageErrorDiscount: '',
      isValidVoucherValue: true,
      searchProduct: '',
      filteredItems: [],
      fieldCustomers: [
        {
          key: 'referenceType',
          label: 'Loại',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'name',
          label: 'Đối tượng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '70%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      customerPhone: '',
      optionsCustomer: [],
      filteredOptionsCustomer: [],
      searchCustomerType: 1,
      alertText: {
        title: {
          voucherDetail: 'Xóa mã voucher!',
          voucherApplyItem: 'Xóa sản phẩm áp dụng!',
          voucherApplyCustomer: 'Xóa khách hàng áp dụng!',
        },
        description: {
          voucherDetail: 'Bạn có chắc muốn xóa mã voucher này không ?',
          voucherApplyItem: 'Bạn có chắc muốn xóa sản phẩm áp dụng này không ?',
          voucherApplyCustomer:
            'Bạn có chắc muốn xóa khách hàng áp dụng này không ?',
        },
      },
    };
  },
  validations: {
    mainModel: {
      startDate: {
        required,
        minValue: minValue(0),
      },
      name: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    VueMultiSelect,
    Autosuggest,
    BInputNumber,
    ImportExcelModal,
    VoucherCumulativeModal,
    VoucherCumulativeValue,
    ImportExcelProduct,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Voucher', route: '/voucher' },
      {
        title: 'Thông tin voucher',
      },
    ]);
    this.mainModel.id = this.$route.query.id;
    if (this.mainModel.id) {
      this.getInfoById();
    }
  },
  computed: {
    convertToISODate() {
      return (date, currentFormat, toFormat = null) => {
        return moment(date, currentFormat).format(toFormat);
      };
    },
    optionItems() {
      return [...cloneDeep(this.filteredItems)];
    },
    itemTypeOptions() {
      if (this.selectTypeSearch === SEARCH_TYPE.PRODUCT) {
        return PRODUCT_RELATIONSHIP_TYPES;
      } else if (this.selectTypeSearch === SEARCH_TYPE.CATEGORY) {
        return [
          {
            id: 0,
            name: 'Tất cả nhóm sản phẩm',
          },
          {
            id: 1,
            name: 'Nhóm sản phẩm cha',
          },
          {
            id: 2,
            name: 'Nhóm sản phẩm con',
          },
        ];
      } else {
        return [
          {
            id: 0,
            name: 'Tất cả thương hiệu',
          },
        ];
      }
    },
  },
  created() {
    this.initFilterOption();
    this.fetchStore();
  },
  methods: {
    showDeleteAlert,
    fetchStore: async function () {
      ApiService.get(`stores?limit=1000&page=1`).then((response) => {
        this.stores = response.data.data.list_store.map((element) => {
          return {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
        });
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.mainModel.details.length; i++) {
        if (this.mainModel.details[i].id === id) {
          this.mainModel.details.splice(i, 1);
        }
      }
    },
    onBack: function () {
      history.back();
    },
    checkValidDataDetail() {
      let check = false;
      if (this.mainModel.details.length === 0) {
        makeToastFaile('Vui lòng nhập mã voucher!');
        return;
      }
      for (let index = 0; index < this.mainModel.details.length; index++) {
        const element = this.mainModel.details[index];
        const item = this.mainModel.details.find(
          (item) => item.code === element.code && item.id !== element.id,
        );
        if (item) {
          makeToastFaile(
            `Mã ${element.code} bị trùng trong danh sách mã voucher!`,
          );
          return;
        }
        const itemNull = this.mainModel.details.find(
          (item) => item.code.trim() === '' || item.code.trim().length < 5,
        );
        if (itemNull) {
          makeToastFaile(`Vui lòng nhập mã voucher hợp lệ (ít nhất 5 ký tự)!`);
          return;
        }
      }
      check = true;
      return check;
    },
    onSubmit: function () {
      if (!this.mainModel.startDate) {
        makeToastFaile('Vui lòng nhập ngày bắt đầu chương trình voucher!');
        return;
      }
      if (!this.mainModel.endDate) {
        makeToastFaile('Vui lòng nhập ngày kết thúc chương trình voucher!');
        return;
      }
      this.validateVoucherValue();
      if (!this.isValidVoucherValue) {
        makeToastFaile('Vui lòng nhập giá trị voucher hợp lệ!');
        return;
      }
      if (!this.checkValidDataDetail()) {
        return;
      }
      let storeIds = '-1';
      if (this.mainModel.storeIds.length > 0) {
        storeIds = '';
        this.mainModel.storeIds.forEach((element, index) => {
          if (index === this.mainModel.storeIds.length - 1) {
            storeIds += element.id;
          } else {
            storeIds += element.id + ', ';
          }
        });
      }
      this.mainModel.details.forEach((element) => {
        element.maxUse = unMaskPrice(element.maxUse);
      });
      const startDate = this.convertToISODate(
        this.mainModel.startDate,
        'DD/MM/YYYY',
        'YYYY-MM-DDT00:00:00.000',
      );
      const endDate = this.convertToISODate(
        this.mainModel.endDate,
        'DD/MM/YYYY',
        'YYYY-MM-DDT23:59:59.000',
      );
      if (moment(startDate).isSameOrAfter(endDate)) {
        makeToastFaile('Ngày bắt đầu coupon không được lớn hơn ngày kết thúc');
        return;
      }
      const payload = {
        id: this.mainModel.id,
        name: this.$v.mainModel.name.$model,
        description: this.mainModel.description,
        isActive: this.mainModel.isActive,
        details: this.mainModel.details,
        applyItems: this.mainModel.applyItems,
        applyCustomers: this.mainModel.applyCustomers,
        startDate: this.mainModel.startDate
          ? moment(this.mainModel.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00',
            )
          : null,
        endDate: this.mainModel.endDate
          ? moment(this.mainModel.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59',
            )
          : null,
        type: this.mainModel.type,
        value: this.mainModel.value ? unMaskPrice(this.mainModel.value) : 0,
        maxValue: this.mainModel.maxValue
          ? unMaskPrice(this.mainModel.maxValue)
          : 0,
        storeIds: storeIds,
        applyFromAmount: this.mainModel.applyFromAmount
          ? unMaskPrice(this.mainModel.applyFromAmount)
          : 0,
        applyChanel: this.mainModel.applyChanel,
        cumluativeValues: this.mainModel.cumluativeValues,
      };
      const cmdMethod = this.mainModel.id ? 'put' : 'post';
      ApiService[cmdMethod]('v2/vouchers', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 200) {
            makeToastSuccess(message);
            this.$router.push({
              name: 'list-voucher',
            });
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    addVoucherCode() {
      if (this.mainModel.details.length > 0) {
        for (let index = 0; index < this.mainModel.details.length; index++) {
          const element = this.mainModel.details[index];
          if (element.code.trim() === '' || element.code.length < 5) {
            makeToastFaile('Vui lòng nhập mã voucher với ít nhất 5 ký tự!');
            return;
          } else {
            const item = this.mainModel.details.find(
              (item) => item.code === element.code && item.id !== element.id,
            );
            if (item) {
              makeToastFaile(`Mã voucher ${element.code} đã tồn tại!`);
              return;
            }
          }
        }
        this.mainModel.details.push({
          id: v4(),
          code: '',
          maxUse: 1,
          used: 0,
        });
      } else {
        this.mainModel.details.push({
          id: v4(),
          code: '',
          maxUse: 1,
          used: 0,
        });
      }
    },
    getInfoById: async function () {
      await ApiService.get('v2/vouchers/' + this.mainModel.id).then((res) => {
        const {
          name,
          description,
          startDate,
          endDate,
          isActive,
          details,
          applyItems,
          applyCustomers,
          type,
          value,
          maxValue,
          applyFromAmount,
          applyChanel,
          cumluativeValues,
        } = res.data.data;
        this.$v.mainModel.name.$model = name;
        this.mainModel.description = description;
        this.mainModel.startDate =
          startDate !== null ? moment(startDate).format('DD/MM/YYYY') : '';
        this.mainModel.endDate =
          endDate !== null ? moment(endDate).format('DD/MM/YYYY') : '';
        this.mainModel.isActive = isActive;
        this.mainModel.isEditable = isActive === 1;
        this.mainModel.details = details;
        this.mainModel.storeIds = res.data.data.storeIds;
        this.mainModel.applyItems = applyItems;
        this.mainModel.cumluativeValues = cumluativeValues;
        this.mainModel.applyCustomers = applyCustomers;
        this.mainModel.applyFromAmount = applyFromAmount;
        this.mainModel.value = value;
        this.mainModel.type = type;
        this.mainModel.maxValue = maxValue;
        this.mainModel.applyChanel = applyChanel;
        const isCreate = this.$route.query.isCreate;
        if (isCreate) {
          this.mainModel.id = null;
          this.mainModel.details = [];
          this.mainModel.isEditable = true;
          this.mainModel.isActive = 1;
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    validateVoucherValue() {
      this.isValidVoucherValue = true;
      if (
        this.mainModel.type === AMOUNT_TYPE.MONEY &&
        Number(this.mainModel.value) > 10000000
      ) {
        this.messageErrorDiscount = 'Giá trị voucher vượt quá 10 triệu VND!';
        this.isValidVoucherValue = false;
      }
      if (
        this.mainModel.type === AMOUNT_TYPE.PERCENT &&
        Number(this.mainModel.value) > 100
      ) {
        this.messageErrorDiscount = 'Giá trị voucher vượt quá 100%!';
        this.isValidVoucherValue = false;
      }
    },
    searchItem(textSearch) {
      this.isSearching = true;
      this.filteredItems = [];
      const params = {
        searchProduct: textSearch,
        typeSearch: this.selectTypeSearch,
        typeOfProduct: this.selectTypeOfProduct,
      };
      ApiService.query(`productSearch/search-by-product-category`, {
        params,
      }).then((response) => {
        this.filteredItems = response.data.data;
        this.isSearching = false;
      });
    },
    searchBrand: function (textSearch) {
      this.listBrand = [];
      const param = {
        page: 1,
        pageSize: 10,
        name: textSearch,
      };
      const paramQuery = {
        params: {
          param,
        },
      };
      ApiService.query('brands', paramQuery).then((response) => {
        this.filteredItems = response.data.brands.map((brand) => {
          return {
            id: brand.id,
            code: brand.name,
            name: brand.name,
          };
        });
      });
    },
    debounceInputSearch: decounce(function () {
      if (!this.isSearching) {
        const textSearch = this.searchProduct
          ? this.searchProduct.trim()
          : null;
        if (!textSearch) {
          return;
        }
        if (this.selectTypeSearch === SEARCH_TYPE.BRAND) {
          this.searchBrand(textSearch);
        } else {
          this.searchItem(textSearch);
        }
      }
    }, TIME_TRIGGER),
    onInputChangeSearch(text) {
      this.searchProduct = text;
      this.debounceInputSearch();
    },
    onSelectedItem(option) {
      this.searchProduct = '';
      const index = this.mainModel.applyItems.findIndex(
        (item) => item.referenceId === option.item.id,
      );
      if (index > -1) {
        return makeToastFaile(`${option.item.name} đã tồn tại`);
      }
      this.mainModel.applyItems.push({
        id: v4(),
        referenceType: this.mapReferenceTypeWithSearch(this.selectTypeSearch),
        referenceId: option.item.id,
        name: option.item.name,
        code: option.item.code,
      });
    },
    mapReferenceTypeWithSearch(searchType) {
      switch (searchType) {
        case SEARCH_TYPE.PRODUCT:
          return 1;
        case SEARCH_TYPE.CATEGORY:
          return 2;
        case SEARCH_TYPE.BRAND:
          return 3;
      }
    },
    searchCustomer() {
      const text = this.customerPhone ? this.customerPhone.trim() : null;
      if (this.searchCustomerType === 1) {
        this.onFilterCustomerType(text);
        return;
      }
      if (!text) {
        return;
      }
      const params = {
        phoneNumber: text,
      };
      ApiService.query(`customer/get-by-phone`, { params }).then((data) => {
        this.optionsCustomer = data.data.data.map((data) => {
          return {
            ...data,
            name: data.fullName,
            customerMobile: data.phoneNo,
          };
        });
        if (this.optionsCustomer.length > 0) {
          this.filteredOptionsCustomer = [...this.optionsCustomer];
        } else {
          this.filteredOptionsCustomer = [
            { message: 'SĐT không có trong hệ thống', customerMobile: text },
          ];
        }
      });
    },
    onFilterCustomerType(textSearch) {
      if (!textSearch) {
        this.filteredOptionsCustomer = cloneDeep(CUSTOMER_TYPE);
        return;
      }
      this.filteredOptionsCustomer = CUSTOMER_TYPE.filter((item) => {
        return item.name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1;
      });
    },
    onSearchCustomerChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.customerPhone = searchQuery;
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer];
      this.debounceInputCustomer();
    },
    debounceInputCustomer: decounce(function () {
      this.searchCustomer();
    }, TIME_TRIGGER),
    initFilterOption() {
      if (this.searchCustomerType === 1) {
        this.filteredOptionsCustomer = cloneDeep(CUSTOMER_TYPE);
      }
    },
    onSelectedCustomer(option) {
      this.customerPhone = '';
      const index = this.mainModel.applyCustomers.findIndex(
        (item) => item.referenceId === option.item.id,
      );
      if (index > -1) {
        return makeToastFaile(`${option.item.name} đã tồn tại`);
      }
      this.mainModel.applyCustomers.push({
        id: v4(),
        referenceType: this.searchCustomerType,
        referenceId: option.item.id,
        name: option.item.name,
        customerMobile: option.item.customerMobile,
      });
    },
    confirmDeleteVoucherDetail(deleteItem) {
      this.mainModel.details = _.reject(this.mainModel.details, (detail) => {
        return detail.id == deleteItem.id;
      });
    },
    confirmDeleteApplyItem(deleteItem) {
      this.mainModel.applyItems = _.reject(
        this.mainModel.applyItems,
        (applyItem) => {
          return applyItem.id == deleteItem.id;
        },
      );
    },
    confirmDeleteApplyCustomer(deleteItem) {
      this.mainModel.applyCustomers = _.reject(
        this.mainModel.applyCustomers,
        (applyCustomer) => {
          return applyCustomer.id == deleteItem.id;
        },
      );
    },
    isDuringEvent() {
      if (!this.mainModel.id) {
        return false;
      }
      const currentDate = moment().format();
      return moment(currentDate).isBefore(this.mainModel.startDate);
    },
    importExcel() {
      this.$bvModal.show('import-voucher-code');
    },
    showModalImportProduct() {
      this.$bvModal.show('import-voucher-product');
    },
    onRecevieVoucherCode(dataResponse) {
      this.mainModel.details = this.mainModel.details.concat(dataResponse);
    },
    onRecevieVoucherProduct(dataResponse) {
      this.mainModel.applyItems = this.mainModel.applyItems.concat(dataResponse);
    },
    showVoucherCumulativeConfigModal() {
      setTimeout(() => {
        this.$refs['voucher-cumluative-modal'].showModal(
          this.mainModel.cumluativeValues,
        );
      }, TIME_TRIGGER);
    },
    onRecevieCumulativeValue(cumluativeValues) {
      this.mainModel.cumluativeValues = cumluativeValues;
    },
    isEditable() {
      const isUsed = this.mainModel.details.find((detail) => detail.used > 0);
      return !this.isDuringEvent() && !isUsed;
    },
    onChangeEditable() {
      this.mainModel.isActive = this.mainModel.isEditable ? 1 : 0;
      const payload = {
        voucherConfigId: this.mainModel.id,
        isActive: this.mainModel.isActive,
      };
      ApiService.put('v2/vouchers/status', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 200) {
            makeToastSuccess(message);
            this.getInfoById();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
  },
};
</script>

<style scoped>
.multi-select-wrapper {
  flex: 1 1 auto;
}
</style>
