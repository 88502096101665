<template>
  <div class="order-page">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="''">
      <template v-slot:preview>
        <b-card no-body class="full-width">
          <div class="wizard-nav-bg">
            <b-col
              class="py-2 mb-1"
              cols="12"
              style="border-bottom: 0.5px solid #e7e7e7"
            >
              <OrderHeader
                ref="orderHeaderRef"
                typeHeader="order-list"
                :userInfor="userInfor"
                :disable="true"
                :apiParams="apiParams"
                :param="selectedOrders"
                :type="type"
                v-on:update-company-component="updateCompanyComponent"
                v-on:set-mode="onSetMode"
              />
            </b-col>
          </div>
          <div
            class="wizard wizard-4"
            id="kt_wizard_v4_order"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <div class="wizard-nav wizard-nav-bg">
              <div class="wizard-steps">
                <div
                  class="wizard-step mb-0"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">Tất cả</div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="checkJobTitle.isCR(userInfor.jobTitleId)"
                  class="wizard-step ml-1 mb-0"
                  data-wizard-type="step"
                >
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">Đơn online</div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="checkJobTitle.isCR(userInfor.jobTitleId)"
                  class="wizard-step ml-1 mb-0"
                  data-wizard-type="step"
                >
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">Đơn trả góp</div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="checkJobTitle.isCR(userInfor.jobTitleId)"
                  class="wizard-step ml-1 mb-0"
                  data-wizard-type="step"
                >
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">ShinhanBank</div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="checkJobTitle.isShop(userInfor.jobTitleId)"
                  class="wizard-step ml-1 mb-0"
                  data-wizard-type="step"
                >
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">Cửa hàng</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-col cols="12" class="py-8 custom-card-body">
              <b-row class="mb-4">
                <b-col>
                  <div class="date-style">
                    <b-input-group>
                      <date-picker
                        placeholder="Từ"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.fromDate"
                      ></date-picker>
                    </b-input-group>
                    <span class="ml-1 mr-1"></span>
                    <b-input-group>
                      <date-picker
                        placeholder="Đến"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.toDate"
                      ></date-picker>
                    </b-input-group>
                  </div>
                </b-col>
                <b-col>
                  <BDropdownCustom
                    v-model="apiParams.storeIds"
                    :options="optionStores"
                    :placeholder="'cửa hàng'"
                    :searchable="true"
                    :matchKey="['name', 'shortName']"
                    :limit="5"
                  />
                </b-col>
                <b-col>
                  <Autosuggest
                    :model="apiParams.employeeName"
                    :suggestions="optionsEmployee"
                    :suggestionName="'fullName'"
                    :placeholder="'nhân viên CSKH'"
                    :limit="100"
                    @select="onSelected($event, 'Employee')"
                    @change="onInputed($event, 'Employee')"
                  >
                    <template #custom="{ suggestion }">
                      {{ suggestion.item.fullName }}
                    </template>
                  </Autosuggest>
                </b-col>
                <b-col>
                  <BDropdownCustom
                    v-model="apiParams.status"
                    :options="optionStatus"
                    :placeholder="'trạng thái'"
                    :searchable="true"
                    :textProp="'text'"
                    :valueProp="'value'"
                    :matchKey="['text']"
                    :limit="5"
                  />
                </b-col>
                <b-col>
                  <b-button-group size="sm">
                    <b-button
                      style="width: 70px"
                      class="font-weight-bolder"
                      variant="primary"
                      @click="onFilter"
                      ><i class="fas fa-search text-white fa-lg"></i
                      >Lọc</b-button
                    >
                    <b-button
                      variant="primary"
                      v-b-toggle.collapse-1
                      class="togge-filter"
                      ><i class="fas fa-angle-down"></i
                    ></b-button>
                  </b-button-group>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col>
                  <b-input
                    placeholder="Nhập id đơn hàng"
                    v-model="apiParams.orderId"
                    size="sm"
                    autocomplete="off"
                    v-focus
                  ></b-input>
                </b-col>
                <b-col>
                  <Autosuggest
                    :model="apiParams.customerPhoneSearch"
                    :suggestions="optionsCustomerPhone"
                    :suggestionName="'phoneNo'"
                    :placeholder="'sđt khách hàng'"
                    :limit="10"
                    @select="onSelectedCustomerPhone"
                    @change="onChangeCustomerPhone"
                  >
                    <template #custom="{ suggestion }">
                      <div class="d-flex flex-column">
                        <span
                          class="text-primary mb-1"
                          v-if="suggestion.item.phoneNo"
                          >({{ suggestion.item.phoneNo }})</span
                        >
                        <span>{{ suggestion.item.fullName }}</span>
                      </div>
                    </template>
                  </Autosuggest>
                </b-col>
                <b-col>
                  <b-input
                    placeholder="Nhập ghi chú CSKH"
                    v-model="apiParams.saleNote"
                    autocomplete="off"
                    size="sm"
                  />
                </b-col>
                <b-col>
                  <treeselect
                    :options="optionCancelStatus"
                    :multiple="false"
                    placeholder="Nhập lý do huỷ"
                    noResultsText="Không có kết quả"
                    :match-keys="['label', 'custom']"
                    :value="apiParams.cancelStatus"
                    v-model="apiParams.cancelStatus"
                    openDirection="bottom"
                  >
                    <label
                      slot="option-label"
                      slot-scope="{ node, labelClassName }"
                      :class="labelClassName"
                    >
                      <span :title="node.label"> {{ node.label }}</span>
                    </label>
                  </treeselect>
                </b-col>
                <b-col>
                  <b-input
                    placeholder="Nhập tên sản phẩm"
                    v-model="apiParams.productSearch"
                    autocomplete="off"
                    size="sm"
                  />
                </b-col>
              </b-row>
              <b-collapse id="collapse-1" class="mt-4" :appear="true">
                <b-row class="mb-4">
                  <b-col>
                    <treeselect
                      :options="optionTypes"
                      :multiple="false"
                      placeholder="Chọn loại đơn"
                      noResultsText="Không có kết quả"
                      :match-keys="['label', 'custom']"
                      :value="apiParams.orderType"
                      v-model="apiParams.orderType"
                      openDirection="bottom"
                    >
                      <label
                        slot="option-label"
                        slot-scope="{ node, labelClassName }"
                        :class="labelClassName"
                      >
                        <span :title="node.label"> {{ node.label }}</span>
                      </label>
                    </treeselect>
                  </b-col>

                  <b-col>
                    <b-input
                      placeholder="Nhập tên khách hàng"
                      v-model="apiParams.customerNameSearch"
                      size="sm"
                      autocomplete="off"
                    ></b-input>
                  </b-col>
                  <b-col>
                    <b-input-group>
                      <b-form-select
                        class="select-style"
                        v-model="apiParams.departmentId"
                        :options="optionsDepartments"
                        size="sm"
                        value-field="id"
                        text-field="name"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Chọn bộ phận</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <BDropdownCustom
                      v-model="apiParams.criteriaIds"
                      :options="optionCriterias"
                      :placeholder="'đặc điểm'"
                      :searchable="true"
                      :matchKey="['name']"
                    />
                  </b-col>
                  <b-col>
                    <b-input
                      placeholder="Nhập Imei sản phẩm"
                      v-model="apiParams.productImeiSearch"
                      size="sm"
                    />
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col>
                    <Autosuggest
                      :model="apiParams.orderSourceName"
                      :suggestions="optionOrderSources"
                      :suggestionName="'name'"
                      :placeholder="'nguồn đơn hàng'"
                      :limit="100"
                      @select="onSelected($event, 'OrderSource')"
                      @change="onInputed($event, 'OrderSource')"
                    />
                  </b-col>
                  <b-col>
                    <b-input-group>
                      <b-form-select
                        class="select-style"
                        v-model="apiParams.customerType"
                        :options="optionsCustomerType"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Chọn loại khách</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <b-input
                      placeholder="Nhập ghi chú của khách"
                      v-model="apiParams.customerNote"
                      size="sm"
                    ></b-input>
                  </b-col>
                  <b-col>
                    <treeselect
                      :options="optionPaymentStatus"
                      :multiple="false"
                      placeholder="Chọn thanh toán"
                      noResultsText="Không có kết quả"
                      :match-keys="['label', 'custom']"
                      v-model="apiParams.partnerPaymentStatus"
                      openDirection="bottom"
                    >
                      <label
                        slot="option-label"
                        slot-scope="{ node, labelClassName }"
                        :class="labelClassName"
                      >
                        <span :title="node.label"> {{ node.label }}</span>
                      </label>
                    </treeselect>
                  </b-col>
                  <b-col>
                    <BDropdownCustom
                      v-if="currentWizard === 4"
                      v-model="apiParams.loanStatus"
                      :options="optionLoanStatus"
                      :placeholder="'trạng thái vay'"
                      :searchable="true"
                      :textProp="'text'"
                      :valueProp="'value'"
                      :matchKey="['text']"
                    />
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col>
                    <b-input-group>
                      <date-picker
                        placeholder="Ngày đến CH / nhận hàng"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="apiParams.checkDate"
                      ></date-picker>
                    </b-input-group>
                  </b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                </b-row>
              </b-collapse>
              <b-row>
                <b-col cols="12">
                  <OrderListTable
                    :paramSearch="paramSearch"
                    ref="orderTableRef"
                    v-on:onCheckBoxOrders="onCheckBoxOrders"
                  />
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-card>
      </template>
    </KTCodePreview>
    <template v-if="checkPermissions(['ORDER_UPDATE'])">
      <OrderChangeCommon
        :mode="mode"
        :userInfor="userInfor"
        v-on:refresh="onRefreshOrder"
      />
    </template>
    <OrderImportExcel v-on:refresh="onRefreshOrder" />
    <template v-if="checkPermissions(['ORDER_UPDATE'])">
      <OrderChangeImportExcel
        :mode="mode"
        :userInfor="userInfor"
        v-on:refresh="onRefreshOrderExcel"
      />
    </template>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { checkPermissions } from '@/utils/saveDataToLocal';
import { removeAccents, makeToastFaile } from '@/utils/common';
import OrderChangeCommon from '@/view/components/order/OrderChangeCommon.vue';
import OrderImportExcel from '@/view/components/order/OrderImportExcel.vue';
import OrderListTable from '@/view/components/order/OrderListTable.vue';
import OrderHeader from '@/view/components/order/OrderHeader.vue';
import SaveDataUtil from '@/utils/saveDataToLocal';
import {
  cloneDeep,
  debounce,
  keyBy,
  entries,
  isEmpty,
  isNil,
  omitBy,
  omit,
} from 'lodash';
import jQuery from 'jquery';
import moment from 'moment';
import {
  DEFAULT_COMANY_ID,
  ORDER_TYPE,
  TIME_TRIGGER,
  ORDER_STATUS,
  ORDER_CANCEL_STATUS,
  ORDER_CANCEL_STATUS_CR,
  ORDER_CANCEL_STATUS_SHOP,
  ORDER_CANCEL_STATUS_DP,
  CRITERIA,
  LOAN_STATUS,
  CUSTOMER_TYPE,
  DEPARTMENT,
  CONSTANT_PARTNER_PAYMENT,
} from '@/utils/constants';
import { CRITERIA_FILTER_BILL, JOB_TITLE_ORDER } from '@/utils/enum';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import decounce from 'debounce';
import timeUtils from '@/utils/date';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import BDropdownCustom from '../../base/bootstrap/BDropdownCustom.vue';
import OrderChangeImportExcel from '../../components/order/OrderChangeImportExcel.vue';
export default {
  data() {
    return {
      wizard: null,
      currentWizard: 1,
      size: 10,
      page: this.$route.query.page || 1,
      dpConfigs: null,
      apiParams: {
        companyId: DEFAULT_COMANY_ID,
        orderSourceId: null,
        orderSourceName: '',
        status: [],
        orderType: null,
        fromDate: null,
        toDate: null,
        storeIds: [],
        cancelStatus: null,
        criteriaIds: [],
        customerNote: null,
        customerSearch: null,
        orderId: null,
        employeeId: null,
        employeeName: '',
        saleNote: null,
        productSearch: null,
        productImeiSearch: null,
        tabName: 'all',
        checkDate: null,
        customerType: null,
        departmentId: null,
        customerNameSearch: null,
        customerPhoneSearch: null,
        partnerPaymentStatus: null,
      },
      optionsCustomerType: [],
      optionStatus: [],
      optionCancelStatus: null,
      filteredOrderSources: [],
      optionTypes: [],
      companies: [],
      optionCriterias: [],
      optionsDepartments: [],
      optionOrigin: {
        orderSources: [],
        employees: [],
        stores: [],
      },
      optionStores: [],
      filteredEmployees: [],
      selectedOrders: [],
      mode: '',
      paramSearch: null,
      settings: [],
      fromUrl: false,
      optionLoanStatus: [],
      userInfor: null,
      filteredOptionsCustomerPhone: [],
      isSearchingCustomerPhone: false,
      paramsUrl: [],
      type: null,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    OrderListTable,
    OrderHeader,
    Treeselect,
    OrderChangeCommon,
    OrderChangeImportExcel,
    OrderImportExcel,
    Autosuggest,
    BDropdownCustom,
  },
  created() {
    this.initialConstant();
    this.getParamsFromUrl();
    this.fetchStores();
    this.fetchOrderSources();
    this.fetchEmployees();
    this.onFilter();
    this.initSetting();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Đơn hàng', route: 'orders' },
      { title: 'Danh sách đơn hàng' },
    ]);

    const query = this.$route.query || null;
    const tabName = query ? query.tabName : '';
    if (tabName) {
      const settings = keyBy(this.settings, 'tableName');
      const settingItem = settings[tabName] || null;
      this.currentWizard = settingItem ? settingItem.currentWizard : 1;
    }

    this.wizard = new KTWizard('kt_wizard_v4_order', {
      startStep: this.currentWizard,
      clickableSteps: true,
    });

    this.$nextTick(function () {
      this.wizard.on('change', this.onChangeTab);
    });

    window.addEventListener('keyup', this.onFilterKeyEnter);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onFilterKeyEnter);
  },
  methods: {
    checkPermissions,
    onPreventEnter(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        return false;
      }
    },
    onFilterKeyEnter(event) {
      if (event.key === 'Enter') {
        const isShown = jQuery('.modal').hasClass('show');
        if (!isShown) this.onFilter();
      }
    },
    initialConstant() {
      const userInfor = this.getEmployeeFromLocal();
      this.userInfor = userInfor;
      this.dpConfigs = timeUtils.DP_CONFIG;
      this.optionsCustomerType = cloneDeep(CUSTOMER_TYPE);
      this.optionStatus = cloneDeep(
        ORDER_STATUS.filter((item) => item.value && !item.checked),
      );

      this.optionTypes = cloneDeep(ORDER_TYPE).reduce((result, element) => {
        if (element.id !== null) {
          result.push({
            id: element.id,
            label: element.name,
            custom: removeAccents(element.name),
            customLabel: element.name,
          });
        }
        return result;
      }, []);

      this.optionPaymentStatus = cloneDeep(CONSTANT_PARTNER_PAYMENT).reduce(
        (result, element) => {
          if (element.id !== null) {
            result.push({
              id: element.id,
              label: element.name,
              custom: removeAccents(element.name),
              customLabel: element.name,
            });
          }
          return result;
        },
        [],
      );
      this.optionCriterias = cloneDeep(CRITERIA);
      this.optionsDepartments = cloneDeep(DEPARTMENT);
      this.optionLoanStatus = cloneDeep(
        LOAN_STATUS.filter((item) => !item.checked),
      );

      let cancelStatus = [];
      const jobTitleId = userInfor.jobTitleId;
      if (this.checkJobTitle.isCR(jobTitleId)) {
        cancelStatus = ORDER_CANCEL_STATUS_CR;
      } else if (this.checkJobTitle.isShop(jobTitleId)) {
        cancelStatus = ORDER_CANCEL_STATUS_SHOP;
      } else if (this.checkJobTitle.isDP(jobTitleId)) {
        cancelStatus = ORDER_CANCEL_STATUS_DP;
      } else {
        cancelStatus = ORDER_CANCEL_STATUS;
      }
      this.optionCancelStatus = cloneDeep(cancelStatus).reduce(
        (result, element) => {
          if (element.value !== null) {
            result.push({
              id: element.value,
              label: element.text,
              custom: removeAccents(element.text),
              customLabel: element.text,
            });
          }
          return result;
        },
        [],
      );
    },
    initSetting() {
      const userInfor = this.userInfor;
      this.settings = [
        {
          tableName: 'all',
          currentWizard: 1,
        },
      ];
      this.getTabJobTitle(userInfor);
    },
    getTabJobTitle(userInfor) {
      if (!userInfor.jobTitleId) {
        makeToastFaile('Nhân viên chưa được gắn kí chức vụ');
        return;
      }

      if (JOB_TITLE_ORDER.CR.includes(userInfor.jobTitleId)) {
        this.settings = this.settings.concat([
          {
            tableName: 'online',
            currentWizard: 2,
          },
          {
            tableName: 'installment',
            currentWizard: 3,
            params: {
              orderSourceId: 756132,
              orderSourceName: 'Order Online',
            },
            mutiselects: {
              criterias: {
                values: [CRITERIA_FILTER_BILL.HAVE_INSTALLMENT],
                prop: 'id',
              },
            },
          },
          {
            tableName: 'shinhan',
            currentWizard: 4,
            params: {
              orderSourceId: 756132,
              orderSourceName: 'Order Online',
            },
            mutiselects: {
              criterias: {
                values: [CRITERIA_FILTER_BILL.HAVE_INSTALLMENT],
                prop: 'id',
              },
              stores: {
                values: [],
                prop: 'id',
              },
            },
          },
        ]);
      } else {
        const storeId = userInfor.storeId || null;
        let mutiselects = {};
        if (storeId) {
          mutiselects = {
            stores: {
              values: [storeId],
              prop: 'id',
            },
          };
        }
        this.settings.push({
          tableName: 'store',
          currentWizard: 2,
          mutiselects: mutiselects,
        });
      }
    },
    getEmployeeFromLocal() {
      const userFromLocal = SaveDataUtil.getData('employee_info');
      if (userFromLocal) {
        const tmpInfo = JSON.parse(userFromLocal);
        return tmpInfo;
      }
    },
    validateState(name) {
      if (!name) return null;
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onChangeTab(item) {
      this.optionCriterias = cloneDeep(CRITERIA);
      this.apiParams.orderSourceId = null;
      this.apiParams.orderSourceName = null;
      this.currentWizard = item.currentStep;

      const setting = this.settings.find((s) => {
        return s.currentWizard === (item ? item.currentStep : 1);
      });

      if (setting) {
        this.$router.replace({
          query: Object.assign({}, this.$route.query, {
            tabName: setting.tableName,
          }),
        });
        this.apiParams.tabName = setting.tableName;

        if (setting.params) {
          for (const [key, value] of entries(setting.params)) {
            this.apiParams[key] = value;
          }
        }

        if (setting.mutiselects) {
          for (const [key] of entries(setting.mutiselects)) {
            const values = setting.mutiselects[key]
              ? setting.mutiselects[key].values
              : [];
            const prop = setting.mutiselects[key]
              ? setting.mutiselects[key].prop
              : [];

            const keyName = key.charAt(0).toUpperCase() + key.slice(1);
            const keyOption = `option${keyName}`;
            const keyId = `${key.slice(0, -1)}Ids`;
            const items = this[`option${keyName}`];

            values.map((id) => {
              const index = items.findIndex((x) => x[prop] === id);
              if (index > -1) {
                this[keyOption][index].checked = true;
                const existId = this.apiParams[keyId].findIndex(
                  (item) => item[prop] === id,
                );
                if (existId < 0) {
                  this.apiParams[keyId].push(items[index]);
                }
              }
            });
          }
        }
      }

      this.fetchDataTable();
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    },
    getParamsFromUrl() {
      const query = this.$route.query || null;

      if (!isEmpty(query)) {
        const statusIds = query.searchStatus
          ? [].concat(query.searchStatus)
          : [];

        const statusCheckeds = [];
        this.optionStatus.map((item) => {
          if (statusIds.includes(item.value)) {
            item.checked = true;
            statusCheckeds.push(item);
          }
        });

        const criteriaIds = query.searchCriteria
          ? [].concat(query.searchCriteria)
          : [];

        const criteriaCheckeds = [];
        this.optionCriterias.map((item) => {
          if (criteriaIds.includes(String(item.id))) {
            criteriaCheckeds.push(item);
          }
        });

        const storeIds = query.searchStore ? [].concat(query.searchStore) : [];
        const storeCheckeds = [];

        this.optionStores.map((item) => {
          if (storeIds.includes(String(item.id))) {
            storeCheckeds.push(item);
          }
        });
        this.apiParams = {
          ...this.apiParams,
          companyId: query.companyId
            ? Number(query.companyId)
            : DEFAULT_COMANY_ID,
          status: statusCheckeds,
          storeIds: storeCheckeds,
          criteriaIds: criteriaCheckeds,
        };
        this.fromUrl = true;
      }
    },
    setFillter() {
      const param = this.getParamFilters();

      this.fromUrl = false;
      return param;
    },
    onFilter() {
      this.onChangeUrl();
      this.fetchDataTable();
    },
    onChangeUrl() {
      this.$route.query.page = 1;
      let param = this.setFillter();
      param = omit(param, ['page', 'pageSize']);
      this.$router.push({
        name: 'list-order',
        query: omitBy(param, isNil),
      });
    },
    fetchDataTable() {
      this.paramSearch = { ...this.getParamFilters() };
    },
    debounceInput: debounce((textInput, fn) => {
      fn(textInput);
    }, TIME_TRIGGER),
    getParamFilters() {
      // get danh sách cửa hàng
      const stores = this.apiParams.storeIds.map((store) => Number(store.id));
      //get danh sách đặc điểm filter
      const criterias = this.apiParams.criteriaIds.map((item) =>
        Number(item.id),
      );
      //get danh sách status filter

      const status = this.apiParams.status.map((item) => Number(item.value));

      const searchLoanStatus = this.getValuesMultiSelect(
        this.optionLoanStatus,
        'value',
      );

      //get danh sách lý do filter
      const cancelStatus = this.apiParams.cancelStatus
        ? Number(this.apiParams.cancelStatus)
        : null;
      //get tab name
      const tabName = this.fromUrl
        ? this.$route.query.tabName || 'all'
        : this.apiParams.tabName;

      return {
        page: this.page,
        pageSize: this.size,
        id: this.apiParams.orderId,
        type: this.apiParams.orderType,
        customerNote: this.apiParams.customerNote,
        saleNote: this.apiParams.saleNote,
        cancelStatus: cancelStatus,
        createdBy: this.apiParams.employeeId,
        searchProduct: this.apiParams.productSearch,
        companyId: this.apiParams.companyId,
        orderSourceId: this.apiParams.orderSourceId,
        searchStore: stores,
        searchStatus: status,
        searchCriteria: criterias,
        searchName: this.apiParams.customerNameSearch,
        searchPhone: this.apiParams.customerPhoneSearch,
        searchFromDay: this.apiParams.fromDate
          ? moment(this.apiParams.fromDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        searchToDay: this.apiParams.toDate
          ? moment(this.apiParams.toDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        searchProductImei: this.apiParams.productImeiSearch,
        tabName: tabName,
        searchLoanStatus: searchLoanStatus,
        checkDate: this.apiParams.checkDate
          ? moment(this.apiParams.checkDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        customerType: this.apiParams.customerType,
        departmentId: this.apiParams.departmentId,
        partnerPaymentStatus: this.apiParams.partnerPaymentStatus,
      };
    },
    getValuesMultiSelect(arr, prop) {
      return arr.reduce((accumulator, currentValue) => {
        if (currentValue.checked) accumulator.push(currentValue[prop]);
        return accumulator;
      }, []);
    },
    onSelected(option, type, value) {
      switch (type) {
        case 'Status':
          this.checkedOptions(this.optionStatus, option, 'value', value);
          break;
        case 'LoanStatus':
          this.checkedOptions(this.optionLoanStatus, option, 'value', value);
          break;
        case 'Employee':
          this.apiParams.employeeId = option.item.id;
          break;
        case 'OrderSource':
          this.apiParams.orderSourceId = option.item.id;
          break;
        default:
          break;
      }
    },
    checkedOptions(items, option, prop, value) {
      const index = items.findIndex((item) => item[prop] === option[prop]);
      if (index > -1) {
        items[index].checked = value;
      }
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'OrderSource':
          this.apiParams.orderSourceId = null;
          this.filteredOrderSources = this.fitlerOptionsBy(
            this.optionOrigin.orderSources,
            textInput,
            'name',
            10,
          );
          if (this.filteredOrderSources.length === 1) {
            this.apiParams.orderSourceId = this.filteredOrderSources[0].id;
          }
          break;
        case 'Employee':
          this.apiParams.employeeId = null;
          this.filteredEmployees = this.fitlerOptionsBy(
            this.optionOrigin.employees,
            textInput,
            'fullName',
            10,
          );
          if (this.filteredEmployees.length === 1) {
            this.apiParams.employeeId = this.filteredEmployees[0].id;
          }
          break;
        default:
          break;
      }
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    onCheckBoxOrders(orders, type) {
      this.selectedOrders = orders;
      this.type = type;
    },
    onSetMode(mode) {
      this.mode = mode;
    },
    onRefreshOrder(check) {
      if (check) {
        this.selectedOrders = [];
        this.fetchDataTable();
      }
      this.$bvModal.hide('md-change-status');
    },
    onRefreshOrderExcel(check) {
      if (check) {
        this.fetchDataTable();
      }
      this.$bvModal.hide('md-change-status-excel');
    },
    refreshSelectedOrder(id) {
      const index = this.orders.findIndex((order) => order.id === id);
      if (index > -1) {
        this.orders[index].selected = false;
      }
    },
    fetchOrderSources: function () {
      ApiService.query(`orderSources/getAll`).then((response) => {
        if (response.data.status) {
          const orderSources = response.data.data;
          this.filteredOrderSources = orderSources;
          this.optionOrigin.orderSources = orderSources;
        }
      });
    },
    fetchStores: function () {
      ApiService.query('stores/getStores').then((response) => {
        if (response.data.status) {
          const stores = response.data.data;
          this.optionStores = stores;
          this.optionOrigin.stores = stores;
        }
      });
    },
    fetchEmployees: function () {
      ApiService.get('employees/getAll').then((response) => {
        if (response.data.status) {
          const employees = response.data.data;
          this.filteredEmployees = employees;
          this.optionOrigin.employees = employees;
        }
      });
    },
    updateCompanyComponent(companyId) {
      this.apiParams.companyId = companyId;
    },
    onChangeCustomerPhone(textInput) {
      const searchText = textInput ? textInput.trim() : null;
      this.apiParams.customerPhoneSearch = searchText;
      this.debounceSearchCustomerPhone();
    },
    debounceSearchCustomerPhone: decounce(function () {
      if (!this.isSearchingCustomerPhone) {
        this.getListCustomer();
      }
    }, TIME_TRIGGER),
    getListCustomer() {
      const searchText = this.apiParams.customerPhoneSearch;
      this.isSearchingCustomerPhone = true;
      this.filteredOptionsCustomerPhone = [];

      ApiService.get(`customer/get-by-phone?phoneNumber=${searchText}`).then(
        (data) => {
          const customers = data.data.data;
          this.filteredOptionsCustomerPhone = customers || [];
          this.isSearchingCustomerPhone = false;
        },
      );
    },
    onSelectedCustomerPhone(option) {
      if (!option) return;
      this.apiParams.customerPhoneSearch = option.item.phoneNo;
    },
    getSuggestionCustomerPhoneValue(suggestion) {
      return suggestion.item.phoneNo;
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return this.layoutConfig('loader.logo');
    },
    optionOrderSources() {
      return cloneDeep(this.filteredOrderSources);
    },
    optionsEmployee() {
      return cloneDeep(this.filteredEmployees);
    },
    optionsCustomerPhone() {
      return cloneDeep(this.filteredOptionsCustomerPhone);
    },
    checkJobTitle() {
      return {
        isCR: (jobTitleId) => {
          return JOB_TITLE_ORDER.CR.includes(jobTitleId);
        },
        isShop: (jobTitleId) => {
          return JOB_TITLE_ORDER.SHOP.includes(jobTitleId);
        },
        isDP: (jobTitleId) => {
          return JOB_TITLE_ORDER.DP.includes(jobTitleId);
        },
      };
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
.order-page {
  .checkbox-style {
    position: absolute;
    right: 0.7vw;
  }

  .custom-card-body {
    padding: 0 2.25rem;
  }

  .date-style {
    display: flex;
    align-items: center;
  }

  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }

  input.form-control {
    border: 1px solid #ced4da;
  }

  .multiselect__tags {
    height: calc(1.35em + 1.1rem + 2px);
    display: block;
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    font-size: 0.925rem;
    min-height: initial;
    padding-top: 0.3rem;
  }

  .multiselect__select {
    width: 3rem;
    height: 2.5rem;
    top: 0.1rem;
  }

  .multiselect__placeholder {
    color: #495057;
    padding-left: 0.2rem;
  }

  .multiselect__element {
    font-size: 0.925rem;
  }

  .multiselect__input {
    padding-top: 0.2rem;
    font-size: 0.925rem;
  }

  .multiselect__single {
    font-size: 0.925rem;
    line-height: 1.9;
    padding-left: 0.2rem;
    display: inline-block;
    max-width: 100%;
  }

  .autosuggest__results {
    position: absolute;
    width: 100%;
    max-height: 200px;
    z-index: 999;
    border: 1px solid #e0e0e0;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__input {
    display: block;
    width: 100%;
    height: calc(1.35em + 1.1rem + 2px);
    line-height: 1.35;
    padding: 0.55rem 0.75rem;
    font-weight: 400;
    font-size: 0.925rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.28rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .autosuggest__input:focus {
    outline: none !important;
    border: 1px solid #92c5f9;
  }

  .autosuggest__results-item:hover {
    background-color: #ddd;
  }

  .autosuggest__results ul li {
    padding: 8px;
  }
  #autosuggest {
    width: 100%;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    flex: 0 0 calc(11% - 0.25rem);
    background-color: #f0f1f9;
  }

  .wizard.wizard-4
    .wizard-nav
    .wizard-steps
    .wizard-step[data-wizard-state='current'] {
    background-color: #ffffff;
  }

  @media (max-width: 1399.98px) {
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
      flex: 0 0 calc(17% - 0.25rem);
    }
  }

  .wizard-nav-bg {
    background-color: #f7f7f7;
    border-radius: 5px 5px 0px 0px;
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
  .togge-filter {
    background-color: #3d8cdd;
    border-color: #3d8cdd;
  }

  .vue-treeselect__input-container {
    padding-top: 0px;
  }

  .vue-treeselect__control {
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    height: 2.5rem;
  }
}
</style>
