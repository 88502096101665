var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container print-page"},[_c('table',[_vm._m(0),_vm._m(1),_c('tr',[_c('td',[_vm._v("Tên máy")]),_c('td',[_vm._v(_vm._s(_vm.productName))]),_c('td',[_vm._v("IMEI")]),_c('td',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.imeiCode))])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{staticStyle:{"width":"20%"}}),_c('col',{staticStyle:{"width":"30%"}}),_c('col',{staticStyle:{"width":"10%"}}),_c('col',{staticStyle:{"width":"30%"}}),_c('col',{staticStyle:{"width":"10%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('img',{staticClass:"logo",attrs:{"src":"/media/logos/ddv_hori_logo.png","alt":"Logo"}})]),_c('td',{staticClass:"header-title",attrs:{"colspan":"4"}},[_vm._v("Phiếu Thông Tin Sản Phẩm")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Mã Quốc Gia")]),_c('td'),_c('td',[_vm._v("Dung lượng")]),_c('td',{attrs:{"colspan":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th'),_c('th'),_c('th'),_c('th'),_c('th')])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Mainboard")]),_c('td',[_vm._v("Nguyên Gốc")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})]),_c('td',[_vm._v("Đã sửa chữa")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Camera Trước")]),_c('td',[_vm._v("Nguyên Gốc")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})]),_c('td',[_vm._v("Đã sửa chữa")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Camera Sau")]),_c('td',[_vm._v("Nguyên Gốc")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})]),_c('td',[_vm._v("Đã sửa chữa")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Khung viền")]),_c('td',[_vm._v("Nguyên Gốc")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})]),_c('td',[_vm._v("Đã sửa chữa")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Loa Trong")]),_c('td',[_vm._v("Nguyên Gốc")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})]),_c('td',[_vm._v("Đã sửa chữa")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Loa Ngoài")]),_c('td',[_vm._v("Nguyên Gốc")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})]),_c('td',[_vm._v("Đã sửa chữa")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"text-align":"center","padding-left":"8px"},attrs:{"colspan":"5"}},[_vm._v("Linh kiện hao mòn theo thời gian")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Pin")]),_c('td',[_vm._v("Nguyên Gốc")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})]),_c('td',[_vm._v("Đã thay thế")]),_c('td',[_c('input',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"type":"checkbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Dung lượng pin còn lại")]),_c('td',[_c('span',[_vm._v("...............")])]),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v("%")]),_c('td'),_c('td')])
}]

export { render, staticRenderFns }