<template>
  <div>
    <b-modal
      ref="apply-voucher-modal"
      hide-footer
      :titleHtml="modalTitle"
      size="xl"
    >
      <v-form ref="form">
        <b-row class="mb-4">
          <b-col md="4">
            <b-input
              size="sm"
              placeholder="Tìm kiếm"
              v-model="searchText"
              append-icon="search"
              single-line
              hide-details
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <b-table :items="listItem" :fields="fields" bordered hover show-empty>
          <template #empty="scope">
            <h5 class="text-center">Không có voucher nào được gợi ý</h5>
          </template>
          <template v-slot:cell(voucherValue)="row">
            <div v-if="row.item.type === DISCOUNT_AMOUNT_TYPE.PERCENT">
              {{ convertPrice(row.item.value) }}
              {{ DISCOUNT_AMOUNT_TYPE_NAME[row.item.type] }}
              <span v-text="'Tối đa: '" class="font-weight-bold" />
              <span
                v-text="convertPrice(row.item.maxValue)"
                class="text-warning"
              />
              <div>
                <i class="fa-solid fa-circle-arrow-right"></i>
                {{ convertPrice(row.item.voucherValue) }}
              </div>
            </div>
            <div v-else>
              {{ convertPrice(row.item.voucherValue) }}
            </div>
            <div class="d-flex justify-content-end">
              <VoucherCumulativeValue
                v-if="row.item.cumluativeValues.length"
                :cumluativeValues="row.item.cumluativeValues"
              />
            </div>
          </template>
          <template v-slot:cell(apply)="row">
            <div class="text-center btn" @click="applyVoucherItem(row.item)">
              <i
                class="fa fa-arrow-circle-down text-success"
                v-b-tooltip.hover
                title="Áp dụng"
              ></i>
            </div>
          </template>
        </b-table>
        <b-button
          style="font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModal"
        >
          Hủy
        </b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { convertPrice } from '@/utils/common';
import { cloneDeep } from 'lodash';
import { DISCOUNT_AMOUNT_TYPE_NAME } from '@/utils/enum-name';
import { DISCOUNT_AMOUNT_TYPE } from '@/utils/enum';
import VoucherCumulativeValue from '@/view/components/voucher/VoucherCumulativeValue.vue';

export default {
  props: [
    'billItemId',
    'productName',
    'storeId',
    'productId',
    'productAmount',
    'customerMobile',
  ],
  components: {
    VoucherCumulativeValue,
  },
  data() {
    return {
      modalTitle: '',
      searchText: '',
      listItem: [],
      listItemDraff: [],
      fields: [
        {
          key: 'voucherCode',
          label: 'Mã voucher',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'voucherName',
          label: 'Tên chương trình',
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' },
        },
        {
          key: 'voucherValue',
          label: 'Giá trị',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          formatter: (value) => {
            return convertPrice(value);
          },
          tdClass: 'text-right',
        },
        {
          key: 'usable',
          label: 'Lượt còn lại',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          formatter: (value) => {
            return convertPrice(value);
          },
          tdClass: 'text-right',
        },
        {
          key: 'apply',
          label: 'Áp dụng',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
      ],
      DISCOUNT_AMOUNT_TYPE_NAME,
      DISCOUNT_AMOUNT_TYPE,
    };
  },
  methods: {
    convertPrice,
    async fetchVoucherUsable() {
      const params = {
        productId: this.productId,
        productAmount: this.productAmount,
        storeId: this.storeId,
        customerPhone: this.customerMobile,
      };
      ApiService.query('v2/vouchers/usable', { params }).then(({ data }) => {
        const listData = data.data;
        this.listItem = listData.map((element) => {
          return {
            ...element,
            checked: false,
          };
        });
        this.listItemDraff = cloneDeep(this.listItem);
      });
    },
    showModal: async function() {
      this.searchText = '';
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Áp dụng voucher cho sản phẩm: <a class='text-primary'>#${this.productName}</a></span>
              </div>`;
      await this.fetchVoucherUsable();
      this.$refs['apply-voucher-modal'].show();
    },
    hideModal: function() {
      this.$refs['apply-voucher-modal'].hide();
    },
    onFilter() {
      const textSearch = this.searchText ? this.searchText.trim() : '';
      this.listItem = this.listItemDraff
        .filter((item) => {
          return (
            item.voucherCode.toLowerCase().indexOf(textSearch.toLowerCase()) >
            -1
          );
        })
        .slice(0, 10);
    },
    applyVoucherItem(voucherItem) {      
      const response = {
        voucherId: voucherItem.voucherId,
        voucherDetailId: voucherItem.voucherDetailId,
        billItemId: this.billItemId,
        voucherCode: voucherItem.voucherCode,
        voucherValue: voucherItem.voucherValue,
        cumluativeValues: voucherItem.cumluativeValues,
        voucherType: voucherItem.type,
      };
      this.$emit('applyVoucherItem', response);
      this.hideModal();
    },
  },
};
</script>
