<template>
  <b-modal
    :id="refModal"
    :ref="refModal"
    title="Thêm sản phẩm từ Excel"
    size="lg"
    hide-footer
  >
    <b-row>
      <b-col>
        <div class="form-group">
          <span>
            <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng
            format, hoặc có thể tải ở
            <a :href="urlExcel">đây</a>
          </span>
        </div>
      </b-col>
    </b-row>
    <!-- Nhập file -->
    <b-row>
      <b-col cols="12">
        <span> <b>Bước 2:</b> Nhập sản phẩm và tải lên </span>
        <UploadFileMulter
          v-on:send-file="getDataFromExcel"
          v-on:clear-file="clearFileExcel"
          :cmdUrl="'v2/vouchers/import-product-excel'"
        />
        <span v-if="fileName"
          >Tên file: <b>{{ fileName }}</b>
        </span>
      </b-col>
    </b-row>

    <b-row class="step-3">
      <b-col>
        <span> <b>Bước 3:</b> Lưu </span>
      </b-col>
    </b-row>
    <b-container class="mt-4 pl-0 pr-0" v-show="importItems.length">
      <h5>
        Danh sách voucher từ file excel( Tổng:
        {{ importItems.length }} sản phẩm)
      </h5>
      <b-table
        responsive
        bordered
        hover
        :fields="fieldImport"
        :items="importItems"
        :current-page="currentPage"
        small
        class="product-table"
      >
        <template v-slot:cell(errors)="row">
          <div class="errors" v-if="row.item.errors.length">
            <template v-for="(error, index) in row.item.errors">
              <p class="mb-0" :key="index">{{ error }}</p>
            </template>
          </div>
        </template>
      </b-table>
    </b-container>
    <div class="footer">
      <b-button
        :disabled="isInValid"
        class="cta-btn"
        variant="primary"
        size="sm"
        @click="save"
        >Lưu</b-button
      >
      <b-button
        class="cta-btn ml-4"
        variant="secondary"
        size="sm"
        @click="$bvModal.hide(refModal)"
        >Hủy</b-button
      >
    </div>
  </b-modal>
</template>

<script>
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';
import { BASE_URL } from '@/utils/constants';
import { v4 } from 'uuid';

export default {
  components: {
    UploadFileMulter,
  },
  data() {
    return {
      refModal: 'import-voucher-product',
      importItems: [],
      fieldImport: [
        {
          key: 'referenceType',
          label: 'Loại',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'code',
          label: 'Mã',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'name',
          label: 'Tên',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'errors',
          label: 'Lỗi',
          thStyle: { fontWeight: 600, color: '#181c32', width: '40%' },
        },
      ],
      fileName: '',
      urlExcel: BASE_URL + 'media/upload/voucher/Temp_Import_VoucherProduct.xlsx',
      file: null,
      currentPage: 1,
      totalRow: 0,
      isInValid: true,
    };
  },
  computed: {},
  mounted() {
    this.importItems = [];
  },
  methods: {
    save() {
      const dataResponse = this.importItems.map((importItem) => {
        return {
          id: v4(),
          referenceType: importItem.type,
          referenceId: importItem.referenceId,
          name: importItem.name,
          code: importItem.code,
        };
      });
      this.$emit('on-receive-product', dataResponse);
      this.$bvModal.hide(this.refModal);
    },
    getDataFromExcel(data, fileName) {
      this.fileName = fileName;
      this.importItems = data.rows.map((item) => {
        let referenceType = '';
        switch(item.type) {
          case 1:
            referenceType = 'Sản phẩm';
            break;
          case 2:
            referenceType = 'Danh mục';
            break;
          case 3:
            referenceType = 'Thương hiệu';
            break;
          default:
            break;
        }
        return {
          referenceType: referenceType,
          ...item,
        };
      });
      const inValidItems = this.importItems.filter(
        (importItem) => !importItem.isValid,
      );
      this.isInValid = !!inValidItems.length;
    },
    clearFileExcel() {
      this.importItems = [];
      this.isInValid = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cta-btn {
  font-weight: 600;
  width: 70px;
}
.footer {
  margin-top: 20px;
}
.step-3 {
  margin-top: 20px;
}
.product-table {
  height: 400px;
}
</style>