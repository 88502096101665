<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin bộ phụ kiện khuyến mãi'">
          <template v-slot:preview>
            <b-form
              ref="form"
              lazy-validation
              class="row"
            >
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col cols="3">
                    <b-form-group id="input-group-name">
                      <label for="input-code">Tên bộ phụ kiện:<span class="text-danger">*</span></label>
                      <b-form-input
                        size="sm"
                        id="input-name"
                        v-model="name"
                        placeholder="Nhập tên"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-code">
                      <label for="input-code">Mã bộ phụ kiện:<span class="text-danger">*</span></label>
                      <b-form-input
                        size="sm"
                        id="input-code"
                        v-model="code"
                        placeholder="Nhập mã"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-start-date">
                      <label for="input-start-date">Ngày bắt đầu:</label>
                      <date-picker
                        placeholder="Từ ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs"
                        v-model="startDate"
                      ></date-picker>
                    </b-form-group>

                    <b-form-group id="input-group-end-date">
                      <label for="input-end-date">Ngày kết thúc:</label>
                      <date-picker
                        placeholder="Đến ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs"
                        v-model="endDate"
                      ></date-picker>
                    </b-form-group>
                  </b-col>

                  <b-col cols="5">
                    <label
                      style="font-weight: 600"
                      for="input-description"
                    >Mô tả:</label>
                    <b-form-textarea
                      id="textarea-description"
                      v-model="description"
                      placeholder="Nhập mô tả"
                      rows="13"
                    ></b-form-textarea>
                  </b-col>
                  <b-col cols="2">
                    <b-form-group>
                      <label
                        style="font-weight: 600"
                        for="input-status"
                      >Trạng thái:</label>
                      <b-form-select
                        size="sm"
                        id="input-3"
                        v-model="isActive"
                        :options="listStatus"
                        required
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group>
                      <label>Kênh áp dụng:</label>
                      <b-form-select
                        size="sm"
                        v-model="applyChannel"
                        :options="listChanel"
                        value-field="id"
                        text-field="name"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="2"></b-col>
                </b-row>
                <b-row>
                  <b-form-group
                    id="input-group-search"
                    class="col-md-12 mt-4 pr-0"
                  >
                    <label for="input-code">Tìm sản phẩm:</label>
                    <b-col
                      md="12"
                      class="row pr-0"
                    >
                      <b-col
                        md="6"
                        class="p-0"
                      >
                        <Autosuggest
                          :model="searchProductAccessory"
                          :suggestions="filteredOptionsProduct"
                          @change="onInputChangeProduct"
                          @select="onSelectedProduct"
                          size="sm"
                          :limit="10"
                          placeholder="tên sản phẩm"
                        >
                          <template #custom="{suggestion}">
                            <div>
                              <span>{{ suggestion.item.productName }}</span>
                            </div>
                          </template>
                        </Autosuggest>
                      </b-col>
                      <b-col
                        md="2"
                        class="p-0"
                      >
                        <b-form-select
                          id="select-product"
                          v-model="selectTypeSearch"
                          :options="listTypeSearch"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="debounceInput"
                        ></b-form-select>
                      </b-col>
                      <b-col
                        md="2"
                        class="p-0"
                      >
                        <b-form-select
                          id="select-product"
                          v-model="selectTypeOfProduct"
                          :options="listTypeOfProduct"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="debounceInput"
                        ></b-form-select>
                      </b-col>
                      <b-col
                        md="2"
                        class="pr-0"
                      >
                        <div class="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-secondary font-weight-bolder btn-sm"
                            @click="showModalImportProduct"
                          >
                            <i
                              style="font-size: 1rem"
                              class="far fa-file-excel"
                            ></i>Nhập SP từ excel
                          </button>
                        </div>
                      </b-col>
                    </b-col>
                  </b-form-group>
                </b-row>

                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fieldProduct"
                        :items="accessoryItems"
                        v-if="renderComponent"
                        :per-page="10"
                        :current-page="currentPageTable"
                        id="my-table-product"
                      >
                        <template v-slot:cell(originalPrice)="row">
                          <span class="text-right">{{
                            formatMoney(row.item.originalPrice)
                          }}</span>
                        </template>
                        <template v-slot:cell(sellingPrice)="row">
                          <span class="text-right">{{
                            formatMoney(row.item.sellingPrice)
                          }}</span>
                        </template>
                        <template v-slot:cell(importPrice)="row">
                          <span class="text-right">{{
                            formatMoney(row.item.importPrice)
                          }}</span>
                        </template>
                        <template v-slot:cell(discount)="row">
                          <b-input-group>
                            <template #prepend>
                              <b-form-select
                                v-model="row.item.discountType"
                                :options="discountOptions"
                                size="sm"
                                value-field="id"
                                text-field="name"
                              >
                              </b-form-select>
                            </template>
                            <b-form-input
                              class="input-style text-right"
                              size="sm"
                              type="text"
                              v-model="row.item.discountValue"
                              placeholder="Chiết khấu"
                              v-mask="mask"
                              @input="handleInputDiscount(row.item)"
                            ></b-form-input>
                          </b-input-group>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger"
                            @click="deleteItemInArray(row.item.productId)"
                            v-b-tooltip
                            title="Xóa"
                          >mdi-delete</v-icon>
                        </template>
                      </b-table>
                      <b-pagination
                        v-show="accessoryItems.length > 10"
                        v-model="currentPageTable"
                        :total-rows="rowsTable"
                        :per-page="10"
                        aria-controls="my-table-product"
                        align="right"
                      ></b-pagination>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    md="4"
                  ></b-col>
                </b-row>

                <div class="separator separator-dashed my-5"></div>

                <div>
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Bộ phụ cấu hình cho
                  </p>
                </div>

                <b-row>
                  <b-col md-4>
                    <b-form-group id="form-group-categories">
                      <label for="input-categories">Danh mục</label>
                      <vue-multi-select
                        id="input-categories"
                        style="margin-bottom: 2rem"
                        v-model="selectedCategory"
                        :options="categories"
                        placeholder="Chọn danh mục"
                        option-label="name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                </b-row>

                <b-row>
                  <b-form-group
                    id="input-group-search"
                    class="col-md-12 pr-0"
                  >
                    <label for="input-code">Tìm sản phẩm:</label>
                    <b-col
                      md="12"
                      class="row pr-0"
                    >
                      <b-col
                        md="6"
                        class="p-0"
                      >
                        <Autosuggest
                          :model="searchProductFor"
                          :suggestions="filteredOptionsProductFor"
                          @change="onInputChangeProductFor"
                          @select="onSelectedProductFor"
                          size="sm"
                          :limit="10"
                          placeholder="tên sản phẩm"
                        >
                          <template #custom="{suggestion}">
                            <div>
                              <span>{{ suggestion.item.productName }}</span>
                            </div>
                          </template>
                        </Autosuggest>
                      </b-col>
                      <b-col
                        md="2"
                        class="p-0"
                      >
                        <b-form-select
                          id="select-product"
                          v-model="selectTypeSearchFor"
                          :options="listTypeSearch"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="debounceInputFor"
                        ></b-form-select>
                      </b-col>
                      <b-col
                        md="2"
                        class="p-0"
                      >
                        <b-form-select
                          id="select-product"
                          v-model="selectTypeOfProductFor"
                          :options="listTypeOfProduct"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="debounceInputFor"
                        ></b-form-select>
                      </b-col>
                      <b-col
                        md="2"
                        class="pr-0"
                      >
                        <div class="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-secondary font-weight-bolder btn-sm"
                            @click="showModalImportProductFor"
                          >
                            <i
                              style="font-size: 1rem"
                              class="far fa-file-excel"
                            ></i>Nhập SP từ excel
                          </button>
                        </div>
                      </b-col>
                    </b-col>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="10"
                  >
                    <div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fields"
                        :items="accessoryProductItems"
                        :per-page="10"
                        :current-page="currentPageMain"
                        id="my-table-main"
                      >
                        <template v-slot:cell(originalPrice)="row">
                          <span class="text-right">{{
                            formatMoney(row.item.originalPrice)
                          }}</span>
                        </template>
                        <template v-slot:cell(sellingPrice)="row">
                          <span class="text-right">{{
                            formatMoney(row.item.sellingPrice)
                          }}</span>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger"
                            @click="showDeleteAlert(modalTitle, modalDesc, row.item, confirmDelete)"
                            v-b-tooltip
                            title="Xóa"
                          >mdi-delete</v-icon>
                        </template>
                      </b-table>
                      <b-pagination
                        v-show="accessoryProductItems.length > 10"
                        v-model="currentPageMain"
                        :total-rows="rowsMain"
                        :per-page="10"
                        aria-controls="my-table-main"
                        align="right"
                      ></b-pagination>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    md="4"
                  ></b-col>
                </b-row>
              </b-container>
            </b-form>
            <b-modal
              ref="import-product-modal"
              hide-footer
              title="Thêm sản phẩm từ Excel"
              id="modal-prevent-closing"
              size="lg"
            >
              <b-row>
                <b-col>
                  <div class="form-group">
                    <span>
                      <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                      đúng format, hoặc có thể tải ở
                      <a :href="urlExcel">đây</a>
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span> <b>Bước 2:</b> Tiến hành Import </span>
                  <div style="padding-top: 15px">
                    <b-form-file
                      placeholder="Hãy chọn một tập tin"
                      @change="selectFile"
                      v-model="file"
                      ref="file-input"
                      class="mb-2"
                    ></b-form-file>
                  </div>
                </b-col>
              </b-row>
              <b-container
                class="mt-4 pl-0 pr-0"
                v-show="importItems.length > 0"
              >
                <h5>
                  Danh sách sản phẩm từ file excel( Tổng:
                  {{ importItems.length }} sản phẩm)
                </h5>
                <b-table
                  id="my-table"
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fieldImport"
                  :items="importItems"
                  :per-page="10"
                  :current-page="currentPage"
                >
                  <template v-slot:cell(originalPrice)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.originalPrice)"></span>
                    </div>
                  </template>

                  <template v-slot:cell(sellingPrice)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.sellingPrice)"></span>
                    </div>
                  </template>

                  <template v-slot:cell(discountValue)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.discountValue)"></span>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-show="importItems.length > 10"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="10"
                  aria-controls="my-table"
                ></b-pagination>
              </b-container>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="validData"
              >Lưu</b-button>
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideModalImportProduct"
              >Hủy</b-button>
            </b-modal>
            <b-modal
              ref="import-product-for-modal"
              hide-footer
              title="Thêm sản phẩm từ Excel"
              id="modal-prevent-closing"
              size="lg"
            >
              <b-row>
                <b-col>
                  <div class="form-group">
                    <span>
                      <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                      đúng format, hoặc có thể tải ở
                      <a :href="urlExcelFor">đây</a>
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span> <b>Bước 2:</b> Tiến hành Import </span>
                  <div style="padding-top: 15px">
                    <b-form-file
                      placeholder="Hãy chọn một tập tin"
                      @change="selectFileFor"
                      v-model="fileFor"
                      ref="file-input"
                      class="mb-2"
                    ></b-form-file>
                  </div>
                </b-col>
              </b-row>
              <b-container
                class="mt-4 pl-0 pr-0"
                v-show="importItemFor.length > 0"
              >
                <h5>
                  Danh sách sản phẩm từ file excel( Tổng:
                  {{ importItemFor.length }} sản phẩm)
                </h5>
                <b-table
                  id="my-table"
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fieldImportFor"
                  :items="importItemFor"
                  :per-page="10"
                  :current-page="currentPageFor"
                >
                  <template v-slot:cell(originalPrice)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.originalPrice)"></span>
                    </div>
                  </template>

                  <template v-slot:cell(sellingPrice)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.sellingPrice)"></span>
                    </div>
                  </template>
                  <template v-slot:cell(returnPrice)="row">
                    <div style="text-align: end">
                      <span v-text="formatMoney(row.item.returnPrice)"></span>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-show="importItemFor.length > 10"
                  v-model="currentPageFor"
                  :total-rows="rowsFor"
                  :per-page="10"
                  aria-controls="my-table"
                ></b-pagination>
              </b-container>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="validDataFor"
              >Lưu</b-button>
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideModalImportProductFor"
              >Hủy</b-button>
            </b-modal>
          </template>
          <template v-slot:foot>
            <b-container class="bv-example-row">
              <b-row>
                <b-button
                  v-if="checkPermission('PRODUCT_PROMOTION_UPDATE')"
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="onSubmit"
                >Lưu</b-button>
                <b-button
                  v-if="checkPermission('PRODUCT_PROMOTION_UPDATE')"
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="onback"
                >Hủy</b-button>
                <b-button
                  v-if="!checkPermission('PRODUCT_PROMOTION_UPDATE')"
                  style="font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="onback"
                >Hủy</b-button>
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.discount {
  display: flex;
}

.center-label label {
  margin-bottom: 0px;
}

.rounded {
  border-radius: 0 !important;
}

.form-group label {
  font-weight: 600;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { cmdUrl } from '@/utils/apiUrl';
import VueMultiSelect from 'vue-simple-multi-select';
import _ from 'lodash';
import {
  calculateRemainingMoney,
  currencyMask,
  unMaskPrice,
  makeToastSuccess,
  makeToastFaile,
} from '@/utils/common';

import debounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_PRODUCT_PROMOTION,
  URL_IMPORT_PRODUCT_FOR_PROMOTION,
} from '@/utils/constants';
import xlsx from 'xlsx';
import { checkPermission } from '@/utils/saveDataToLocal';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { DP_CONFIG } from '@/utils/date';
import { COUPON_SCOPE_CHANNEL, DISCOUNT_AMOUNT_TYPE } from '@/utils/enum';
import { showDeleteAlert } from '@/utils/sweet-alert2';

export default {
  data() {
    return {
      modalTitle: 'Xóa sản phẩm!',
      modalDesc: 'Bạn có chắc muốn xóa sản phẩm này không ?',
      applyChannel: COUPON_SCOPE_CHANNEL.BOTH,
      listChanel: [
        {
          id: 1,
          name: 'Offline & Online',
        },
        {
          id: 2,
          name: 'Offline',
        },
        {
          id: 3,
          name: 'Online',
        },
      ],
      dpConfigs: DP_CONFIG.date,
      startDate: new Date(),
      endDate: '',
      fields: [
        {
          key: 'productCode',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-left',
        },
        {
          key: 'productName',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-left',
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-right',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-right',
        },
        { key: 'actions', label: '' },
      ],
      listProductFor: [],
      filteredOptionsProductFor: [],
      optionsProductFor: [],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [],
      code: '',
      name: '',
      description: '',
      searchProductAccessory: '',
      accessoryItems: [],
      accItemsDumb: [],
      accessoryProductItems: [],
      accProductItemsDumb: [],
      searchProductFor: '',
      categories: '',
      selectedCategory: [],
      stores: '',
      id: '',
      listStatus: [
        {
          id: true,
          name: 'Hoạt động',
        },
        {
          id: false,
          name: 'Đã khóa',
        },
      ],
      isActive: true,
      isSearching: false,
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      listTypeOfProduct: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeOfProduct: 0,
      selectTypeSearchFor: 1,
      selectTypeOfProductFor: 0,
      importItems: [],
      file: null,
      excellist: [],
      progress: 0,
      uploading: false,
      fieldImport: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
        {
          key: 'originalPrice',
          label: 'Giá vốn',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
        },
        {
          key: 'discountType',
          label: 'Loại chiết khấu',
        },
        {
          key: 'discountValue',
          label: 'Giá trị chiết khấu',
        },
      ],
      currentPage: 1,
      importItemFor: [],
      fileFor: null,
      excellistFor: [],
      currentPageFor: 1,
      fieldImportFor: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
        {
          key: 'originalPrice',
          label: 'Giá vốn',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
        },
      ],
      urlExcel: URL_IMPORT_PRODUCT_PROMOTION,
      urlExcelFor: URL_IMPORT_PRODUCT_FOR_PROMOTION,
      currentPageMain: 1,
      currentPageTable: 1,
      fieldProduct: [
        {
          key: 'productCode',
          label: 'Mã SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'productName',
          label: 'Tên SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        // },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          tdClass: 'text-right',
        },
        {
          key: 'discount',
          label: 'Chiết khấu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-right',
        },
        {
          key: 'importPrice',
          label: 'Giá bán sau chiết khấu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          tdClass: 'text-right',
        },
        { key: 'actions', label: '' },
      ],
      renderComponent: true,
      mask: currencyMask,
      discountOptions: [
        {
          id: 1,
          name: '$',
        },
        {
          id: 2,
          name: '%',
        },
      ],
    };
  },
  components: {
    Autosuggest,
    KTCodePreview,
    VueMultiSelect,
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
    rowsFor() {
      return this.importItemFor.length;
    },
    rowsTable() {
      return this.accessoryItems.length;
    },
    rowsMain() {
      return this.accessoryProductItems.length;
    },
  },
  mounted() {
    let titleName = this.checkPermission('PRODUCT_PROMOTION_UPDATE')
      ? 'Cập nhật bộ phụ kiện khuyến mãi'
      : 'Chi tiết bộ phụ kiện khuyến mãi';
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Bộ phụ kiện khuyến mãi', route: '/promotion-accessories' },
      {
        title: titleName,
        route: `/promotion-accessories/update-promotion-accessories?id=${this.id}`,
      },
    ]);
  },
  created() {
    this.id = this.$route.query.id;
    this.getCategories();
    this.getAccessoryById();
  },
  methods: {
    showDeleteAlert,
    handleInputDiscount(payload) {
      if (!payload) return;

      const price = calculateRemainingMoney(
        payload.discountType,
        Number(unMaskPrice(payload.discountValue)),
        payload.sellingPrice,
      );

      payload.importPrice = Number(unMaskPrice(price));
    },
    getCategories() {
      ApiService.query('category')
        .then(({ data }) => {
          if (data.status === 1) {
            this.categories = data.data.list_caterogy;
          } else {
            makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    getAccessoryById: async function () {
      if (!this.id) {
        return;
      }
      const enpoint = cmdUrl.PromotionUrl.byId.replace('{id}', this.id);
      await ApiService.get(enpoint).then((res) => {
        let {
          name,
          code,
          applyChannel,
          description,
          startDate,
          endDate,
          products: accessoryItems,
          appliedProducts: accessoryProductItems,
          appliedCategories: categories,
          isActive,
        } = res.data.data;
        this.name = name;
        this.code = code;
        this.description = description;
        this.startDate = startDate
          ? moment(startDate).format('DD/MM/YYYY')
          : null;
        this.endDate = endDate ? moment(endDate).format('DD/MM/YYYY') : null;
        this.accessoryItems = accessoryItems;
        this.accessoryProductItems = accessoryProductItems;
        this.accProductItemsDumb = this.accessoryProductItems;
        this.selectedCategory = categories;
        this.isActive = isActive;
        this.applyChannel = applyChannel;

        // convert import price.
        _.map(this.accessoryItems, (item) => {
          item.importPrice = item.repurchasePrice;
        });
        const isCreate = this.$route.query.isCreate;
        if (isCreate) {
          this.id = null;
          this.name = '';
          this.code = '';
        }
      });
    },
    updateItem(item) {
      this.accessoryItems.forEach((element, index) => {
        if (element.productId === item.productId) {
          this.accessoryItems[index].importPrice = item.importPrice;
        }
      });
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    confirmDelete(deleteItem) {
      this.accessoryProductItems = _.reject(
        this.accessoryProductItems,
        deleteItem,
      );
    },
    deleteItemInArray(productId) {
      for (var i = 0; i < this.accessoryItems.length; i++) {
        if (this.accessoryItems[i].productId === productId) {
          this.accessoryItems.splice(i, 1);
        }
      }
    },
    fetchProduct(textSearch) {
      this.listProduct = [];
      this.optionsProduct = [];
      ApiService.get(
        `productSearch/search-by-type?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearch}&typeOfProduct=${this.selectTypeOfProduct}`,
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          const existedProduct = this.accessoryItems.find(
            (item) => item.productCode === element.productCode,
          );
          if (!existedProduct) {
            this.optionsProduct.push(element);
          }
        });
        this.filteredOptionsProduct = [...this.optionsProduct];
        if (textSearch !== this.searchProductAccessory) {
          let nextTextSearch = this.searchProductAccessory;
          this.fetchProduct(nextTextSearch);
        }
      });
    },
    fetchProductFor(textSearch) {
      this.listProduct = [];
      this.optionsProductFor = [];
      ApiService.get(
        `productSearch/search-by-type?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearchFor}&typeOfProduct=${this.selectTypeOfProductFor}`,
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          const existedProduct = this.accessoryProductItems.find(
            (item) => item.productCode === element.productCode,
          );
          if (!existedProduct) {
            this.optionsProductFor.push(element);
          }
        });
        this.filteredOptionsProductFor = [...this.optionsProductFor];
        if (textSearch !== this.searchProductFor) {
          let nextTextSearch = this.searchProductFor;
          this.fetchProduct(nextTextSearch);
        }
      });
    },
    onSelectedProduct(option) {
      this.searchProductAccessory = '';

      let accessoryItems = {
        productId: option.item.productId,
        productName: option.item.productName,
        productCode: option.item.productCode,
        originalPrice: option.item.originalPrice,
        sellingPrice: option.item.sellingPrice,
        discountType: option.item.discountType || 1,
        discountValue: option.item.discountValue || 0,
        importPrice: option.item.importPrice,
        deleted: 0,
      };

      this.accessoryItems.push(accessoryItems);
    },
    onInputChangeProduct(text = '') {
      this.searchProductAccessory = text;
      this.debounceInput();
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';

      let accessoryProductItem = {
        productId: option.item.productId,
        productName: option.item.productName,
        productCode: option.item.productCode,
        originalPrice: option.item.originalPrice,
        sellingPrice: option.item.sellingPrice,
        deleted: 0,
      };

      this.accessoryProductItems.push(accessoryProductItem);
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;

      this.debounceInputFor();
    },
    onback: function () {
      history.back();
    },
    update(data) {
      const enpoint = cmdUrl.PromotionUrl.byId.replace('{id}', this.id);
      ApiService.patch(enpoint, data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    create(data) {
      ApiService.post(cmdUrl.PromotionUrl.root, data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    onSubmit() {
      this.accessoryItems.forEach((element) => {
        element.importPrice = Number(unMaskPrice(element.importPrice));
        element.discountValue = Number(unMaskPrice(element.discountValue));
      });
      let params = {
        id: this.id,
        name: this.name,
        code: this.code,
        applyChannel: this.applyChannel,
        description: this.description,
        products: this.accessoryItems,
        appliedCategories: this.selectedCategory,
        appliedProducts: this.accessoryProductItems,
        isActive: this.isActive,
        startDate: this.formatDate(this.startDate),
        endDate: this.formatDate(this.endDate),
      };
      // convert import price.
      _.map(params.products, (item) => {
        item.repurchasePrice = item.importPrice;
      });

      if (this.id) {
        this.update(params);
      } else {
        this.create(params);
      }
    },
    debounceInput: debounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductAccessory;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    debounceInputFor: debounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductFor;
        this.fetchProductFor(textSearch);
      }
    }, TIME_TRIGGER),
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx',
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              productName: element['Tên sản phẩm'] || '',
              productCode: element['Mã sản phẩm'] || '',
              originalPrice: element['Giá vốn'] || 0,
              sellingPrice: element['Giá bán'] || 0,
              discountType:
                element['Loại chiết khấu'] || DISCOUNT_AMOUNT_TYPE.MONEY,
              returnPrice: element['Giá bán sau chiết khấu'] || 0,
              discountValue: element['Giá trị chiết khấu'] || 0,
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      let data = {
        listProduct: this.importItems,
      };
      ApiService.post('accessories/valid-import-product', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.accessoryItems.push(element);
            });
            this.hideModalImportProduct();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err;
          makeToastFaile(message);
        });
    },
    selectFileFor(event) {
      this.importItemFor = [];
      const files = event.target.files[0];
      this.fileFor = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.fileFor = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx',
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellistFor = excellist;
          this.excellistFor.map((element) => {
            let data = {
              productName: element['Tên sản phẩm']
                ? element['Tên sản phẩm']
                : '',
              productCode: element['Mã sản phẩm'] ? element['Mã sản phẩm'] : '',
              originalPrice: element['Giá vốn'] ? element['Giá vốn'] : 0,
              sellingPrice: element['Giá bán'] ? element['Giá bán'] : 0,
              returnPrice: element['Giá thu lại'] ? element['Giá thu lại'] : 0,
            };
            this.importItemFor.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    showModalImportProductFor() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-for-modal'].show();
    },
    hideModalImportProductFor() {
      this.$refs['import-product-for-modal'].hide();
    },
    validDataFor() {
      let data = {
        listProduct: this.importItemFor,
      };
      ApiService.post('accessories/valid-import-product', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.accessoryProductItems.push(element);
            });
            this.hideModalImportProductFor();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err;
          makeToastFaile(message);
        });
    },
    checkPermission(condition) {
      return checkPermission(condition);
    },
    formatDate (date) {
      if (!date) return '';
      return moment(date, 'YYYY-MM-DD', true).isValid()
        ? date
        : moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    },
  },
};
</script>
