<template>
  <div>
    <b-row>
      <div style="margin-left: 20px; margin-right: 20px; width: 100%">
        <b-row>
          <b-col md="4">
            <img
              src="media/logos/ddv_hori_logo.png"
              alt="vertu image"
              class="heading-logo"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="1"></b-col>
          <b-col md="10">
            <h5 class="label-color text-center font-size-label-print title">
              QUYỀN LỢI BẢO HÀNH MỞ RỘNG ĐỘC QUYỀN TẠI DI ĐỘNG VIỆT
            </h5>
          </b-col>
          <b-col md="1"></b-col>
        </b-row>
        <b-row style="margin-top: 50px">
          <b-col md="8">
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Đơn hàng</span>
              : {{ bill.billNumber }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">IMEI máy</span>:
              {{ warrantyPackage.imeiNo }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print"
                >Ngày áp dụng gói BHMR</span
              >
              :
              {{
                formatDate(warrantyPackage.warrantyApplyDate, CLIENT_DATE_TIME)
              }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print"
                >Ngày kết thúc gói BHMR</span
              >
              :
              {{
                warrantyPackage.warrantyExpiredDate &&
                formatDate(
                  warrantyPackage.warrantyExpiredDate,
                  CLIENT_DATE_TIME,
                )
              }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Tên khách hàng</span>
              : {{ bill.customerName }}
            </p>
            <p class="content-info font-size-print">
              <span class="label-color font-size-print">Điện thoại</span>
              : {{ bill.customerMobile }}
            </p>
          </b-col>
          <b-col md="4"> </b-col>
        </b-row>
        <p class="label-color font-size-print benfit-label">
          QUYỀN LỢI GÓI BHMR:
          {{ warrantyPackage.warrantyBenefitInfo.typeName?.toUpperCase() }}
        </p>
        <pre class="no-border-pre-wrap benefit-content border">
          {{
            ensureNewlineAtStart(
              warrantyPackage.warrantyBenefitInfo.warrantyBenefit,
            )
          }}
        </pre>
        <p class="label-color font-size-print policy-label">
          CHÍNH SÁCH VÀ ĐIỀU KIỆN BẢO HÀNH:
        </p>
        <div
          v-if="warrantyPackage.warrantyBenefitInfo.link"
          class="qr-code-container"
        >
          <div class="qr-content">
            <qr-code
              :text="warrantyPackage.warrantyBenefitInfo.link"
              :size="200"
              error-level="L"
              class="qr-code"
            ></qr-code>
            <p class="qr-text font-size-print">
              Quét mã QR hoặc tải ứng dụng D-member để xem chi tiết chính sách
              bảo hành
            </p>
          </div>
        </div>
        <pre class="no-border-pre-wrap policy-content" v-else>
          {{
            ensureNewlineAtStart(
              warrantyPackage.warrantyBenefitInfo.warrantyPolicy,
            )
          }}
        </pre>
      </div>
    </b-row>
  </div>
</template>
<script>
import { formatDate, CLIENT_DATE_TIME } from '@/utils/date';
import VueQRCodeComponent from 'vue-qrcode-component';

export default {
  props: ['bill', 'warrantyPackage'],
  data() {
    return {
      CLIENT_DATE_TIME: CLIENT_DATE_TIME,
    };
  },
  components: {
    'qr-code': VueQRCodeComponent,
  },
  methods: {
    formatDate,
    ensureNewlineAtStart(text) {
      if (text.startsWith('\n')) {
        return text;
      }
      return '\n' + text;
    },
  },
};
</script>
<style scoped>
* {
  font-family: 'Roboto', Helvetica, 'sans-serif', 'Poppins';
}
.label-color {
  color: black;
  font-weight: 600;
}
.content-info {
  color: black;
  font-weight: 500;
  margin-top: 10px;
}
.font-size-print {
  color: black;
  font-size: 13px;
}
.heading-logo {
  object-fit: contain;
  width: 80%;
}
.font-size-label-print {
  font-size: 30px;
  margin-bottom: 10px;
}
.no-border-pre-wrap {
  border: none;
  box-shadow: none;
  resize: none;
  background-color: transparent;
  outline: none;
  white-space: pre-wrap;
  padding-left: 0;
  margin-left: 0;
}
.border {
  border: 2px solid #000 !important;
  padding-left: 30px;
  border-radius: 10px;
}
.benfit-label {
  margin-bottom: 20px;
}
.policy-label {
  margin-bottom: 0px;
  margin-top: 20px;
}
.benefit-content {
  font-size: 22px;
  color: #000;
  font-weight: 600;
}
.policy-content {
  font-size: 17px;
  color: #000;
  padding-left: 10px;
}
.title {
  padding-bottom: 30px;
  padding-top: 30px;
}
.qr-code-container {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  margin: 30px 0;
}
.qr-content {
  padding-left: 16px;
}
.qr-text {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin: 10px 0px;
  margin-left: -16px;
}
</style>
