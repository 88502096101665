<template>
  <div>
    <KTCodePreview v-bind:title="'In Hóa đơn'">
      <template v-slot:toolbar>
        <b-form-group
          label="Đặt khổ in mặc định"
          v-slot="{ ariaDescribedby }"
          style="font-size: small; color: black"
        >
          <b-form-radio-group
            v-model="printMode"
            :options="printModeOptions"
            :aria-describedby="ariaDescribedby"
            name="plain-inline"
            plain
            @change="onChangeDefaultMode()"
          ></b-form-radio-group>
        </b-form-group>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder"
          @click="onClickToPrint(printMode)"
        >
          <i style="font-size: 1rem" class="fas fa-print"></i>In hóa đơn
        </b-button>
      </template>
      <template v-slot:preview>
        <hr
          class="hr-text"
          data-content="Khổ giấy A4- A5"
          style="font-weight: 600"
          v-show="printMode === 'A4'"
        />
        <div id="A4" v-if="renderComponent" v-show="printMode === 'A4'">
          <PrintBill :bill="bill" :productUsages="productUsages" />
        </div>
        <br />
        <hr
          class="hr-text"
          data-content="Khổ giấy K80"
          style="font-weight: 600"
          v-show="printMode === 'K80'"
        />
        <div id="K80">
          <PrintBillK80
            :bill="bill"
            :productUsages="productUsages"
            v-show="printMode === 'K80'"
          />
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { convertNumberToText } from '@/utils/common';
import PrintBill from '../../components/bills/PrintBill';
import PrintBillK80 from '../../components/bills/PrintBillK80.vue';
// import PrintBillMini from '../../components/bills/PrintBillMini.vue';
import { TIME_TRIGGER } from '@/utils/constants';
import saveData from '@/utils/saveDataToLocal';
import { BILL_ITEM_TYPE, AMOUNT_TYPE, STORE_TYPE } from '@/utils/enum';

export default {
  components: {
    KTCodePreview,
    PrintBill,
    PrintBillK80,
    // PrintBillMini,
  },
  data() {
    return {
      bill: {
        customerType: '',
        billNumber: '',
        storeId: '',
        storeName: '',
        customerName: '',
        customerPhone: '',
        listBillItem: [],
        totalAmount: 0,
        textAmount: '',
        orderNote: '',
        saleName: '',
        cashierName: '',
        storeAddress: '',
        createdAt: '',
        warrantyNote: '',
        depositAmount: 0,
        transferFromOrder: 0,
        customerPoint: 0,
        totalPay: 0,
        moneyCredit: 0,
        moneyInstallment: 0,
        pointUse: 0,
        subtractPoint: 0,
        totalItemAmount: 0,
        discountValue: 0,
        customerAddress: '',
        sumQuantity: 0,
        sumTotalDiscount: 0,
        sumUnitAmount: 0,
        sumTotalAmount: 0,
        tradeIn: {},
        discountAmount: 0,
      },
      productUsages: [],
      totalQuantity: 0,
      renderComponent: false,
      printMode: 'A4',
      printModeOptions: [
        { text: 'Khổ A4- A5', value: 'A4' },
        { text: 'Khổ K80', value: 'K80' },
      ],
    };
  },
  async created() {
    let defaultPrintMode = saveData.getData('defaultPrintMode');
    if (!defaultPrintMode) {
      defaultPrintMode = 'A4';
      saveData.saveData('defaultPrintMode', this.printMode);
    }
    this.printMode = defaultPrintMode;
    const billNumber = this.$route.query.id;
    await this.getBillById(billNumber);
    await this.getCustomerLevel();
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn', route: '/bills' },
      { title: 'In hóa đơn' },
    ]);
  },
  methods: {
    getBillById: async function(billNumber) {
      this.renderComponent = false;
      await ApiService.get(`bills/${billNumber}`).then((response) => {
        const { data } = response.data;
        const bill = this.mappingBill(data);
        this.productUsages = this.handleProductUsage(bill.listBillItem);
        this.bill = bill;
        this.renderComponent = true;
      });
      // this.onClickToPrint(this.printMode);
    },
    getCustomerLevel: async function() {
      this.renderComponent = false;
      await ApiService.get(
        `customer/statistical-bill?customerPhone=${this.bill.customerPhone}`,
      ).then((response) => {
        const { data } = response.data;
        const { customerType } = data;
        this.bill.customerType = { ...customerType };
      });
      this.renderComponent = true;

      // this.onClickToPrint(this.printMode);
    },
    mappingBill(data) {
      const totalPay = this.calcTotalPay(data);
      const amountConvertText = convertNumberToText(totalPay);
      const amountTextUpperCase = this.upperCaseFirstLetter(amountConvertText);
      const billCreteria = this.calcSumCreteria(data);
      return {
        listBillItem: this.mappingBillItems(data.billItems),
        billNumber: data.billNumber,
        storeId: data.storeId,
        storeName: data.storeName,
        storeAddress: data.storeAddress,
        storeTypeName:
          data.storeType === STORE_TYPE.VDD ? 'VIỆN DI ĐỘNG' : 'DI ĐỘNG VIỆT',
        storeType: data.storeType,
        storeHotline: data.storeHotline,
        customerName: data.customerName,
        customerPhone: data.customerPhone,
        totalAmount: data.totalAmount,
        orderNote: data.customerNote,
        saleName: data.saleName,
        cashierName: data.cashierName,
        createdAt: data.createdAt,
        warrantyNote: data.warrantyNote,
        depositAmount: data.depositAmount,
        transferFromOrder: data.transferFromOrder,
        customerPoint: data.customerPoint,
        moneyInstallment: data.moneyInstallment,
        moneyCredit: data.moneyCredit,
        subtractPoint: data.subtractPoint ? data.subtractPoint : 0,
        pointUse: data.pointToMoney ? data.pointToMoney : 0,
        totalItemAmount: data.totalItemAmount,
        discountValue: data.discountValue,
        customerAddress: data.customerBillAddress,
        totalPay: totalPay,
        textAmount: amountTextUpperCase,
        customerShipFee: data.customerShipFee,
        conversionFee: data.conversionFee,
        sumQuantity: billCreteria.quantity,
        sumTotalDiscount: billCreteria.discount,
        sumUnitAmount: billCreteria.unitAmount,
        sumTotalAmount: billCreteria.totalAmount,
        tradeIn: data.tradeIn,
        discountAmount: data.discountAmount,
        customerType: data.customerType,
        sumTotalDiscountCustomer: billCreteria.customerDiscount,
      };
    },
    calcSumCreteria(data) {
      const sumCreteria = {
        quantity: 0,
        discount: 0,
        unitAmount: 0,
        totalAmount: 0,
        customerDiscount: 0,
      };
      if (!data) {
        return sumCreteria;
      }
      data.billItems.forEach((item) => {
        //  rewrite some properties

        item.price = item.productPrice;
        item.discount = item.discountAmount;
        // total giá chiết khấu
        item.sellingPriceDiscount = item.productPrice * item.quantity;
        // let totalAmountItem = 0
        //  sum quantity
        sumCreteria.quantity += item.quantity;

        //  sum discount
        if (
          item.billItemType === BILL_ITEM_TYPE.PRODUCT &&
          item.discountType === AMOUNT_TYPE.MONEY &&
          item.discountAmount > 0
        ) {
          sumCreteria.discount += item.discount;
          item.sellingPriceDiscount =
            item.sellingPriceDiscount - item.discount < 0
              ? 0
              : item.sellingPriceDiscount - item.discount;
        }

        if (
          item.billItemType === BILL_ITEM_TYPE.PRODUCT &&
          item.discountType === AMOUNT_TYPE.PERCENT &&
          item.discountAmount > 0
        ) {
          // discount price = (percentage discount * product price) / 100 (%)
          const discount =
            (item.discountAmount * item.sellingPriceDiscount) / 100;
          sumCreteria.discount += discount;

          item.sellingPriceDiscount =
            item.sellingPriceDiscount - discount < 0
              ? 0
              : item.sellingPriceDiscount - discount;
        }

        //  sum unit price
        if (
          item.billItemType === BILL_ITEM_TYPE.PRODUCT ||
          item.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION
        ) {
          sumCreteria.unitAmount += item.price * item.quantity;
        }

        if (
          item.customerTypeId &&
          item.billItemType !== BILL_ITEM_TYPE.PRODUCT_BONUS
        ) {
          if (item.customerDiscountType === 'percent') {
            let amount =
              item.sellingPriceDiscount * (item.customerDiscountAmount / 100);
            if (
              item.customerDiscountMaxAmount &&
              amount > item.customerDiscountMaxAmount
            ) {
              amount = item.customerDiscountMaxAmount;
            }
            sumCreteria.customerDiscount += amount;
            item.sellingPriceDiscount = item.sellingPriceDiscount - amount;
          }
          if (item.customerDiscountType === 'amount') {
            sumCreteria.customerDiscount += item.customerDiscountAmount;

            item.sellingPriceDiscount =
              item.sellingPriceDiscount - item.customerDiscountAmount;
          }
        }
      });

      sumCreteria.totalAmount =
        sumCreteria.unitAmount -
        sumCreteria.discount -
        sumCreteria.customerDiscount;

      return sumCreteria;
    },

    mappingBillItems(billItems) {
      let stt = 0;
      return billItems.reduce((result, element) => {
        if (element.billItemType === BILL_ITEM_TYPE.PRODUCT) stt++;
        // total giá chiết khấu
        let productPrice = +element.productPrice * element.quantity || 0;
        let discountItemAmount = 0;

        if (element.discountType * 1 === 2 && element.discountAmount > 0) {
          let discountAmount = (productPrice * element.discountAmount) / 100;
          productPrice = productPrice - discountAmount;

          discountItemAmount += discountAmount;
        }

        if (element.discountType * 1 === 1) {
          productPrice = productPrice - element.discountAmount;
          discountItemAmount += element.discountAmount;
        }

        if (element.customerDiscountType === 'percent') {
          let amount =
            productPrice * (Number(element.customerDiscountAmount) / 100);
          if (
            element.customerDiscountMaxAmount &&
            amount > element.customerDiscountMaxAmount
          ) {
            amount = element.customerDiscountMaxAmount;
          }

          // @TODO: Test lại productPrice có cần sử dụng giá sản phẩm cuối
          // productPrice = Number(productPrice) - Number(amount);
          discountItemAmount += amount;
        }

        if (element.customerDiscountType === 'amount') {
          // @TODO: Test lại productPrice có cần sử dụng giá sản phẩm cuối
          // productPrice = productPrice - element.customerDiscountAmount;
          discountItemAmount += element.customerDiscountAmount;
        }

        let discountAmountValue = discountItemAmount;
        const billItem = {
          stt: stt,
          id: element.productId,
          name: element.productName,
          code: element.productCode,
          price: element.productPrice,
          type: element.productType,
          quantity: element.quantity,
          imeiCode: element.imeiCode,
          totalPrice:
            element.quantity * element.productPrice - discountAmountValue,
          discount: discountItemAmount,
          discountAmountValue: discountAmountValue,
          comboItems: element.listProductInCombo,
          billItemType: element.billItemType,
          discountAmount: element.discountAmount,
          discountType: element.discountType * 1,
          productNote: element.productNote ? element.productNote.trim() : null,
          warrantyMonthNo: element.warrantyMonthNo,
          totalAmountItem: 0,
          customerTypeId: element.customerTypeId,
          customerDiscountType: element.customerDiscountType,
          customerDiscountAmount: element.customerDiscountAmount,
          customerDiscountSellingPrice: element.customerDiscountSellingPrice,
          listedPrice: element.listedPrice,
        };
        billItem.totalAmountItem = this.calTotalAmountItem(billItem);
        // billItem.totalAmountItem = this.calTotalAmountItem(billItem);
        result.push(billItem);
        this.totalQuantity += element.quantity;

        return result;
      }, []);
    },
    handleProductUsage(items) {
      const productUsages = items.reduce((result, product) => {
        const item = result[product.productId];
        const productNote = product.productNote;
        if (!item && productNote) {
          result[product.productId] = {
            productId: product.productId,
            productName: product.productName,
            note: productNote,
          };
        }
        return result;
      }, {});
      return Object.values(productUsages);
    },
    calcTotalPay(data) {
      const sum = [
        'depositAmount',
        'transferFromOrder',
        'moneyCredit',
        'moneyInstallment',
        'pointToMoney',
      ].reduce((result, item) => {
        const amount = data[item] || 0;
        return result + amount;
      }, 0);

      const conversionFee = data.conversionFee || 0;
      const totalPay =
        data.totalAmount - sum + data.customerShipFee + conversionFee;
      return totalPay;
    },
    upperCaseFirstLetter(text) {
      return text.charAt(1).toUpperCase() + text.slice(2).toLowerCase();
    },
    printData(mode, stylePrint) {
      const prtHtml = document.getElementById(mode).innerHTML;
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
          ${stylePrint}
        </head>
        <body`);
      WinPrint.document.write(`${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function() {
        WinPrint.print();
      }, TIME_TRIGGER);
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    calTotalAmountItem(item) {
      let result = 0;
      if (item.discountType === 2) {
        result = item.price * item.quantity - item.discount;
      } else {
        result = item.price - item.discount;
      }
      return this.formatMoney(result);
    },
    getStylePrint() {
      if (this.printMode === 'K80') {
        return `<style>
           .font-size-print {font-size: 16px !important;}
           .font-size-product-info{ font-size: 15px !important;}
           .font-size-price{ font-size: 23px !important; font-weight: bold !important;}
           .text-download-app {font-size: 14px !important; font-weight: 500 !important; margin-top: 4px;}
          @media print {
            @page {
              size: 97mm 297mm;
            }
          }
          @media print {
            html,
            body {
              width: 97mm;
              height: 297mm;
              display: block !important;
              line-height: 1 !important;
              font-family: Time New Roman, sans-serif;
            }
          }
            * {
              box-sizing: border-box;
            }

            .text-center {
              text-align: center;
            }

            .text-right {
              text-align: right;
            }

            .customer {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 1rem;
            }

            .customer .text-title {
              min-width: 80px;
              display: inline-block;
              position: relative;
            }

            .customer .text-title::after {
              content: ':';
              position: absolute;
              right: 0;
            }

            .text-bold-600 {
              font-weight: 600;
            }

            .list-product {
              display: contents;
            }
            .heading-logo {
              object-fit: contain;
            }
            .bill-content {
              font-weight: 500;
              line-height: 1.6;
              color: #000000;
              margin-bottom: 0;
            }
            tr th {
              color: #000000;
              font-weight: 500;
            }

            tr td {
              color: #000000;
              font-weight: 500;
            }

            .heading {
              text-align: center;
              margin-bottom: 10px;
              font-size: 16px;
            }

            .loyalty {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-top: 0;
              gap: 1rem;
            }

            .loyalty .text-title {
              min-width: 160px;
              display: inline-block;
              position: relative;
            }

            .loyalty .text-title::after {
              content: ':';
              position: absolute;
              right: 0;
            }

            hr {
              border: none;
              border-top: 2px dotted black;
              color: #fff;
              background-color: #fff;
              height: 2px;
            }
            hr.dotted {
              border: none;
              border-top: 1px dotted cadetblue;
              color: #fff;
              background-color: #fff;
              height: 1px;
            }

            .product-table {
              margin: 10px 0;
              width: 100%;
            }

            .product-table thead tr {
              border-top: 1px dashed black;
              border-bottom: 1px dashed black;
              line-height: 30px;
            }

            .product-table tfoot {
              border-top: 1px dashed black;
              border-bottom: 1px dashed black;
              line-height: 34px;
            }

            .product-table tbody tr {
              line-height: 24px;
            }

            .list-product tr:last-child {
              border-bottom: 1px dashed black;
            }

            .text-bold {
              color: black;
              font-weight: 600;
            }

            .text-500 {
              color: black;
              font-weight: 500;
            }

            .text-align-center {
              text-align: center;
            }

            .text-styles {
              color: black;
              font-size: 13px;
            }
            .content-info {
              color: black;
              font-weight: 450;
            }
            .font-size-print {
              font-size: 14px;
            }
            .content-product {
              line-height: 1.375;
              white-space: pre-wrap;
              font-size: 13px;
              margin-top: 4px;
            }
            .item-print {
              width: 97mm !important;
              margin-left: 30px !important;
            }
            .text-download-app {
              color: black;
              font-weight: 500;
              margin-top: 4px;
            }
           </style>
           `;
      }
      return `<style>
                .font-size-print {font-size: 20px !important; color: black;}
                .font-size-gift {font-size: 18px !important; color: black;}
                .font-size-name{ font-size: 20px !important; color: black;}
                .font-size-warranty{ font-size: 20px !important; color: black;}
                .font-size-product-info{ font-size: 20px !important; color: black;}
                .font-size-price{ font-size: 30px !important; font-weight: bold !important;}
                .text-download-app {font-size: 14px !important; font-weight: 550 !important; margin-top: 4px;}
                .item-print {
                    width: 100% !important;
                    margin-left: 0px !important;
                  }
              </style>`;
    },
    onClickToPrint(mode) {
      this.printMode = mode;
      const stylePrint = this.getStylePrint();
      this.printData(mode, stylePrint);
    },
    onChangeDefaultMode() {
      saveData.saveData('defaultPrintMode', this.printMode);
    },
  },
};
</script>
