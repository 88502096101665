<template>
  <b-row class="mb-3 check-valuation-table">
    <div>
      <label class="font-weight-bold">
        {{ group.criterialName }}
      </label>
      <b-badge
        :variant="group.type === 1 ? 'secondary' : 'primary'"
        class="ml-2"
      >{{ group.type === 1 ? '1 lựa chọn' : 'Nhiều lựa chọn' }} </b-badge>
    </div>

    <b-table
      class="table-bordered table-hover col-md-12"
      :fields="fields"
      :items="group.listItem"
      small
    >
      <template #cell(amount)="row">
        <div class="text-right pr-2">
          <span v-if="row.item.operatorType === 1">(+)</span>
          <span v-else-if="row.item.operatorType === 2">(-)</span>
          <span>{{ convertPrice(row.item.amount) }}</span>
        </div>
      </template>

      <template #cell(selected)="row">
        <div class="text-center">
          <b-form-checkbox
            v-if="row.item.optionType === 2"
            :id="`${row.item.id}`"
            v-model="row.item.selected"
          >
          </b-form-checkbox>

          <b-form-radio
            v-else
            v-model="row.item.selected"
            :name="`radio-${group.id}`"
            :id="`radio-${row.item.id}`"
            :value="row.item.id"
            @click.native.prevent="onRadioChange(group, row.item)"
          ></b-form-radio>
        </div>
      </template>
    </b-table>
  </b-row>
</template>

<script>
import { convertPrice } from '@/utils/common';

export default {
  name: 'ValuationTable',
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: 'Tiêu chí',
          sortable: false,
          thStyle: {
            fontWeight: '600',
            color: '#181c32',
            textAlign: 'center',
            width: '50%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'selected',
          label: 'Lựa chọn',
          sortable: false,
          thStyle: {
            fontWeight: '600',
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amount',
          label: 'Giá trị',
          sortable: false,
          thStyle: {
            fontWeight: '600',
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
        },
      ];
    },
  },
  methods: {
    convertPrice,
    onRadioChange(group, item) {
      this.$emit('radio-change', group, item);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/sass/components/_variables.bootstrap.scss';
.check-valuation-table {
  .custom-checkbox {
    .custom-control-label {
      &::before {
        border-radius: 0;
      }
    }
  }
}
</style>
