<template>
  <b-row class="mb-3">
    <label
      class="col-md-3 pl-0 font-weight-bold"
      for="input-status"
    >Thông tin sản phẩm</label>
    <b-row class="col-md-12 mb-3 m-0 p-0">
      <b-col class="col-md-3 p-0">
        <b-form-select
          v-model="localSelectTypeSearch"
          :options="listTypeSearch"
          size="sm"
          value-field="id"
          text-field="name"
          @change="onTypeChange"
          class="select-style"
        ></b-form-select>
      </b-col>
      <b-col class="col-md-9 p-0">
        <Autosuggest
          :model="value"
          :suggestions="options"
          :placeholder="placeholder"
          :limit="10"
          @select="onSelect"
          @change="onChange"
          suggestionName="productName"
        >
          <template #custom="{ suggestion }">
            <div class="d-flex align-items-center font-weight-bold">
              <span>{{ suggestion.item.productName }}</span>
            </div>
          </template>
          <template #after-section>
            <div
              class="p-2 text-center"
              v-if="!options.length"
            >
              <div v-if="!isLoading">
                <img
                  src="@/assets/image/no-results.png"
                  alt="No Results"
                />
                <b class="ml-2">Không có kết quả</b>
              </div>
              <b-spinner
                label="Spinning"
                v-else
              ></b-spinner>
            </div>
          </template>
        </Autosuggest>
      </b-col>
    </b-row>
  </b-row>
</template>

<script>
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  name: 'ProductSearch',
  components: {
    Autosuggest,
  },
  props: {
    selectTypeSearch: {
      type: Number,
      required: true,
    },
    listTypeSearch: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localSelectTypeSearch: this.selectTypeSearch,
      placeholder: 'Tên, mã, mã vạch sản phẩm',
    };
  },
  watch: {
    selectTypeSearch(newVal) {
      this.localSelectTypeSearch = newVal;
    },
  },
  methods: {
    onTypeChange() {
      this.$emit('type-change');
    },
    onSelect(option) {
      this.$emit('product-select', option);
    },
    onChange(text) {
      this.$emit('input-change', text);
    },
    setPlaceholder(newPlaceholder) {
      this.placeholder = newPlaceholder;
    },
  },
};
</script>

<style scoped>
.select-style {
  width: 100%;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
