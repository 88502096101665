<template>
  <div>
    <b-form>
      <b-form-group>
        <b-form-radio-group
          :disabled="!isModeAdd"
          v-model="vatInvoice.billVatType"
          class="mt-2"
          :options="VAT_TYPE"
          name="radio-inline"
        ></b-form-radio-group>
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-between align-items-center">
          <label class="mt-2 font-weight-bold">{{ bindingLabel.taxCode }}</label>
          <div class="border border-warning pl-2">
            <b-form-radio-group
              v-model="fromPartner"
              :options="fromPartnerOptions"
              name="service-radio"
            />
          </div>
        </div>
        <Autosuggest
          :disabled="!isModeAdd"
          :state="stateTaxCode"
          :model.sync="taxCodeModel"
          :suggestions="filteredTaxCode"
          placeholder="mã số thuế"
          :limit="10"
          @select="onSelectedTaxCode($event)"
          @change="onChangeTaxCode"
          :errorMessages="'Mã số thuế không hợp lệ'"
          suggestionName="taxCode"
        >
          <template #custom="{ suggestion }">
            <div v-if="suggestion.item.error">
              <p class="font-weight-bolder mb-0">
                {{ suggestion.item.error }}
              </p>
            </div>
            <div v-else>
              <p class="font-weight-bolder mb-0">
                <span class="text-warning">MST: </span
                >{{ suggestion.item.taxCode }}
              </p>
              <span class="font-weight-bolder" v-if="suggestion.item.vatName">
                {{ suggestion.item.vatName }}
              </span>
              <p class="mb-0" v-if="suggestion.item.vatAddress">
                <span class="text-dark font-weight-bolder">ĐC: </span
                >{{ suggestion.item.vatAddress }}
              </p>
            </div>
          </template>
        </Autosuggest>
      </b-form-group>
      <b-form-group v-if="vatInvoice.billVatType === 2">
        <label class="mt-2 font-weight-bold">Số điện thoại:</label>
        <b-form-input
          :disabled="!isModeAdd"
          :state="statePhone"
          class="input-style"
          size="sm"
          trim
          v-model="vatInvoice.phone"
          placeholder="Nhập số điện thoại"
        ></b-form-input>
        <b-form-invalid-feedback :state="statePhone"
          >Số điện thoại không hợp lệ</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-between align-items-center">
          <label class="font-weight-bold">{{ bindingLabel.billVatName }}</label>
          <a
            class="mb-3"
            href="http://tracuunnt.gdt.gov.vn/tcnnt/mstdn.jsp"
            target="_blank"
            >Tra cứu Thuế <i class="flaticon2-search text-primary"></i
          ></a>
        </div>
        <b-form-input
          :disabled="!isModeAdd"
          class="input-style"
          size="sm"
          type="text"
          v-model="vatInvoice.billVatName"
          :placeholder="
            vatInvoice.billVatType === 2 ? 'Nhập họ và tên' : 'Nhập tên công ty'
          "
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <label class="font-weight-bold">{{
          bindingLabel.billVatAddress
        }}</label>
        <b-form-input
          :disabled="!isModeAdd"
          class="input-style"
          size="sm"
          type="text"
          v-model="vatInvoice.billVatAddress"
          :placeholder="
            vatInvoice.billVatType === 2
              ? 'Nhập địa chỉ'
              : 'Nhập địa chỉ công ty'
          "
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <label class="font-weight-bold">Email:</label>
        <b-form-input
          :disabled="!isModeAdd"
          :state="stateEmail"
          class="input-style"
          size="sm"
          type="email"
          v-model="vatInvoice.email"
          placeholder="Email, ngăn cách nhiều email bởi dấu ' ; '"
          trim
        ></b-form-input>
        <b-form-invalid-feedback :state="stateEmail"
          >Email không hợp lệ</b-form-invalid-feedback
        >
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import decounce from 'debounce';

import ApiService from '@/core/services/api.service';

import { isValidEmail, isValidPhoneNumber } from '@/utils/validation';
import { VAT_TYPE, TIME_TRIGGER } from '@/utils/constants';
import { BILL_VAT_TYPE } from '@/utils/enum';
import { xoa_dau, makeToastSuccess, makeToastFaile } from '@/utils/common';

import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  props: {
    vatInvoice: {
      type: Object,
      required: true,
    },
    vatBill: {
      type: Object,
      required: true,
    },
    isModeAdd: {
      type: Boolean,
    },
  },
  components: { Autosuggest },
  data() {
    return {
      VAT_TYPE,
      filteredTaxCode: [],
      notFoundTaxCode: null,
      BILL_VAT_TYPE,
      fromPartner: 'MIFI',
      fromPartnerOptions: [
        { value: 'MIFI', text: 'MIFI' },
        { value: 'FAST', text: 'FAST' },
      ],
    };
  },
  watch: {
    'vatInvoice.billVatType'(newType) {
      const isPersonal = newType === 2;

      if (isPersonal) {
        const customerPhone = this.vatBill.customerPhone;
        const customerAddress = this.vatBill.customerAddress;
        const customerName = this.vatBill.customerName;
        const email = this.vatBill.email;

        this.vatInvoice.taxCode = '';
        this.vatInvoice.phone = customerPhone;
        this.vatInvoice.billVatAddress = customerAddress;
        this.vatInvoice.billVatName = customerName;
        this.vatInvoice.email = email;
        return;
      }
      this.resetBillVatInvoiceInfo();
    },
  },
  created() {},
  computed: {
    bindingLabel() {
      const isCompanyVatType =
        this.vatInvoice.billVatType === BILL_VAT_TYPE.PERSONAL;

      return {
        taxCode: isCompanyVatType ? 'Mã số thuế:' : 'Mã số thuế cá nhân:',
        billVatName: isCompanyVatType ? 'Tên công ty:' : 'Họ và tên:',
        billVatAddress: isCompanyVatType ? 'Địa chỉ công ty:' : 'Địa chỉ:',
      };
    },
    taxCodeModel: {
      get: function () {
        if (!this.isModeAdd && !this.vatInvoice.taxCode) {
          return 'Nhập mã số thuế';
        }
        return this.vatInvoice.taxCode;
      },
      set: function (newValue) {
        this.vatInvoice.taxCode = newValue;
      },
    },
    stateTaxCode() {
      if (!this.vatInvoice.taxCode) return null;

      const isTenNumber = /^[0-9]{10}$/.test(this.vatInvoice.taxCode);
      const isTenDashThreeNumber = /(\d{10}-\d{3})$/.test(
        this.vatInvoice.taxCode,
      );

      const state = isTenNumber || isTenDashThreeNumber;
      this.$emit('update:isValidTaxCode', state);

      return state;
    },
    stateEmail() {
      if (!this.vatInvoice.email) {
        this.$emit('update:isValidEmail', null);
        return null;
      }
      const { email } = this.vatInvoice;
      const MAX_EMAIL_LENGTH = 150;
      if (email.length > MAX_EMAIL_LENGTH) {
        this.$emit('update:isValidEmail', false);
        return false;
      }

      const emails = email.split(';');
      let state = true;
      for (const item of emails) {
        const stringTrim = item.trim();
        state = isValidEmail(stringTrim);
      }
      this.$emit('update:isValidEmail', state);
      return state;
    },
    statePhone() {
      if (!this.vatInvoice.phone) return null;

      const phone = this.vatInvoice.phone.trim();
      const state = isValidPhoneNumber(phone);
      this.$emit('update:isValidPhone', state);

      return state;
    },
  },
  methods: {
    onSelectedTaxCode(option) {
      const mappingTaxMifi = {
        taxCode: 'taxCode',
        vatAddress: 'billVatAddress',
        vatName: 'billVatName',
      };

      for (const key of Object.keys(option.item)) {
        if (!this.notFoundTaxCode && option.item[key]) {
          this.vatInvoice[mappingTaxMifi[key]] = option.item[key];
        }
      }
    },
    async fetchTaxCode(searchQuery) {
      const url = 'vat-bills/tax-search';
      const method = 'query';
      const params = { taxCode: searchQuery, fromPartner: this.fromPartner };

      try {
        const response = await ApiService[method](url, { params });
        makeToastSuccess(response.data.message);
        this.filteredTaxCode = [{ ...response.data.data }];
        this.notFoundTaxCode = '';
      } catch (error) {
        this.filteredTaxCode = [];
        this.notFoundTaxCode = 'Không tìm thấy kết quả tìm kiếm';
        this.filteredTaxCode.push({ error: this.notFoundTaxCode });
        if (error.response) {
          makeToastFaile(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại',
          );
        } else {
          makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
        }
      }
    },
    debounceInput: decounce(function (searchQueryNoneSign) {
      this.fetchTaxCode(searchQueryNoneSign);
    }, TIME_TRIGGER),
    onChangeTaxCode(searchQuery) {
      this.filteredTaxCode = [];
      if (!searchQuery) {
        searchQuery = '';
        this.notFoundTaxCode = '';
      }
      const textInput = searchQuery ? searchQuery.trim() : null;
      const searchQueryNoneSign = xoa_dau(textInput);
      this.debounceInput(searchQueryNoneSign);
    },
    resetBillVatInvoiceInfo() {
      this.vatInvoice.taxCode = '';
      this.vatInvoice.billVatName = '';
    },
  },
};
</script>
